/* Container principal */
.InicioModerador {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.InicioModerador h2 {
    text-align: center;
    font-size: 32px;
}
.InicioModerador h3 {
    text-align: center;
    font-size: 28px;
    margin-top: 1em;
    font-weight: bold;
    margin-bottom: 1em;
}
/* Bem vindo */
.InicioModerador p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 1360px) {       
    .InicioModerador {
        padding: 1em 0.5em; 
    }  
}

