/* Container principal */
.Cabecalho {
    background: url("../Cabecalho/fundo.png") no-repeat;
    background-size: cover;
    height: 80vh;
    display: flex;  
    align-items: center;    
    box-sizing: border-box;    
}

/* Div abaixo, sendo a unica */
.Cabecalho div {
    margin-left: 2em;
    color: var(--white);
}

/* Titulo */
.Cabecalho div h1 {
    font-size: 32px;
}

/* Textos */
.Cabecalho div h2 {
    font-size: 28px;
    margin-top: 0.8em;
}

@media screen and (max-width: 1100px) {       
    .Cabecalho {     
        background-size: auto;
    }    
}

@media screen and (max-width: 500px) {       
    .Cabecalho div h1 {     
        font-size: 24px;
    }   
    .Cabecalho div h2 {     
        font-size: 18px;
    }  
}


@media screen and (max-width: 400px) {       
    .Cabecalho {     
        height: 60vh;
    }    
}

