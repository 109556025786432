/* Imagem do modal */
.ImagemModal {
    height: auto;
    width: 100px;
}
/* Link da palavra clique */
.Clique {
    color: var(--pink);
}
/* Link da palavra clique ao passar o mouse */
.Clique:hover {
    color: var(--green);
}