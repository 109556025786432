/* Container principal */
.RecusadosAnunciante {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}

/* Título */
.RecusadosAnunciante h3 {
    margin-bottom: 1.5em;
    text-align: center;
}

/* Cor na tabela */
.RecusadosAnunciante a {
    color: var(--dark-blue);
}

.RecusadosAnunciante aside {
    display: flex;
    justify-content: space-around;
    width: auto;
    align-items: center;
    gap: 1em;
    margin: 1em 0;
}

/* Título de pesquisa e tabela */
.RecusadosAnunciante h6 {
    margin: 1em 0;
    text-align: center;
    font-size: 26px;
}

.RecusadosAnunciante aside div {
    margin: 0 !important;
}


/* Container maior */
.RecusadosAnunciante div {
    margin: 0.6em;
}

/* container menor */
/* .AceitarInfluenciadores div div {    
    border: solid black 1px;
} */

.RecusadosAnunciante div label {
    display: none;
}

.RecusadosAnunciante nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.RecusadosAnunciante nav div div {
    border: solid black 1px;
}

@media screen and (max-width: 850px) {
    .RecusadosAnunciante aside {
        flex-direction: column;
        gap: 1em;
    }
}

@media screen and (max-width: 700px) {
    .RecusadosAnunciante nav {
        flex-direction: column;
    }
}

@media screen and (max-width: 550px) {
    .CelulaBotoes {
        width: 300px;
    }
    
}