/* Container principal */
.EditarCampanhas {       
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.EditarCampanhas h3 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 1.2em;
}

.EditarCampanhas table {
    width: auto;
}

@media screen and (max-width: 1360px) {       
    .EditarCampanhas {
        padding: 1em 0.5em; 
    }  
}

