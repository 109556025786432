/* Container principal */
.UsuariosRecusados {       
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.UsuariosRecusados h2 {
    text-align: center;
    font-size: 32px;
}
/* Bem vindo */
.UsuariosRecusados p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.UsuariosRecusados div {
    margin-top: 2em;
    display: flex;
    gap: 1em;
}

@media screen and (max-width: 1360px) {       
    .UsuariosRecusados {
        padding: 1em 0.5em; 
    }  
}

