/* Container principal */
.SolicitarTrocaCupom {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.SolicitarTrocaCupom h2 {
    text-align: center;
    font-size: 32px;    
}
/* Bem vindo */
.SolicitarTrocaCupom p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.8em;
}

.SolicitarTrocaCupom form {      
    border-radius: 20px;
    padding: 36px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column;  
    color: var(--white);   
    gap: 1em;
    margin-bottom: 1em;
}

.SolicitarTrocaCupom form h3 {
    text-align: center;
    font-size: 22px;
}

/* Container do texto */
.SolicitarTrocaCupom form div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px; 
}

.SolicitarTrocaCupom form div strong {
    font-weight: bold;
}
/* Container do ícone */
.SolicitarTrocaCupom form div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.SolicitarTrocaCupom form div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;  
}

.SolicitarTrocaCupom form span {
    font-size: 20px;
    font-weight: bold;
}

.SolicitarTrocaCupom form ul li label {
    display: none;
}

.SolicitarTrocaCupom form ul li div {
    width: 100%;
}

.SolicitarTrocaCupom form ul li {
    list-style: none;
}



@media screen and (max-width: 1360px) {       
    .SolicitarTrocaCupom {
        padding: 1em 0.5em; 
    }  
}

