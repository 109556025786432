/* Container principal */
.Carteira {       
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}
/* Titulo principal */
.Carteira h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
    font-weight: bold;
}
/* Saldo */
.Carteira p {
    font-size: 26px;
    color: var(--dark-blue);
}

@media screen and (max-width: 1360px) {       
    .Carteira {
        padding: 1em 0.5em; 
    }  
}

