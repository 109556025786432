/* Essa sessao vai mudar completamente, por esta dessa forma sem ajeitar */

.Planos {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em; 
    box-sizing: border-box;   
}

.Planos h2, .Planos label {
    text-align: center;
    color: var(--dark-blue);
}

.Planos h3 {
    color: var(--pink);
    font-size: 18px;
}

.Planos h2 {
    font-size: 36px;    
}

.Planos p {
    font-size: 26px;   
    color: var(--white); 
}

.Card {
    background-color: lightgray;
    width: 350px;
    height: 175px;
    padding: 1.5em;
    background-image: linear-gradient(to right, var(--dark-blue), var(--indigo), var(--pink));

    display: flex;
    flex-direction: column;
    gap: 1em;
    box-sizing: border-box;
}

.firstDiv {
    display: flex;
    justify-content: space-between;
}

.Label {
    color: var(--white);
}

.Sensor {
    color: var(--green-sensor);
}

.secondDiv {
    display: flex;
    justify-content: space-between;    
}

.secondDiv span p, .firstDiv label {
    color: var(--white);
    font-size: 18px;
}

.Chip {
    color: var(--gold-chip);
}

.thirdDiv {
    display: flex;
    /* margin-top: 1em; */
    justify-content: flex-end;
}

.Card:hover {
    box-shadow: var(--dark-blue) 0px 35px 60px -12px;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.025);
    transition: 0.18s;
    cursor: pointer;
}

