/* Container principal */
.ConteudoPopUpMini {
    display: flex;
    flex-direction: column;
    padding: 0.4em;    
}
/* Container do ícone e texto */
.ConteudoPopUpMini a span {
    display: flex;
    align-items: center;
    gap: 0.4em;
}
/* Ícone */
.ConteudoPopUpMini a span svg {
    color: var(--pink);
}
/* Texto */
.ConteudoPopUpMini a span label {
    color: black ;
}

.ConteudoPopUpMini a span svg:hover, .ConteudoPopUpMini a span label:hover {
    color: var(--dark-blue);
    cursor: pointer;
}