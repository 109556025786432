/* Container principal */
.NovoChat {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: lightgray;   */
}

/* Outra pessoa do chat */
.NovoChat nav {
    width: 100%;
    display: flex;
}

.NovoChat span {
    width: 100%;
}

/* Container da imagem e nome da pessoa do outro chat */
.NovoChat nav div {
    display: flex;
    align-items: center;
    background-color: lightgray;
    width: 100%;
    padding: 0.6em;
    gap: 1em;
}
/* Container da pergunta se deseja aceitar o influenciador */
.NovoChat nav div:nth-child(2) div {
    width: auto;
    margin-left: auto;
}
/* Icone da outra pessoa do chat */
.NovoChat nav div img {
    width: auto;
    height: 50px;
    margin-left: 1.2em;
}

/* Nome da outra pessoa do chat e da pergunta se deseja aceitar o influenciador */
.NovoChat nav div p,
.NovoChat nav div:nth-child(2) div label {
    font-weight: bold;
    font-style: italic;
}

/* Conversa */
.NovoChat main {
    width: 100%;
    height: 62.52vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Adiciona scroll vertical se o conteúdo ultrapassar a altura */
    overflow-x: hidden;
    /* Evita scroll horizontal (opcional) */
}

/* Container de cada mensagem do outro */
.LadoOutraPessoa {
    width: 100%;
    display: flex;
}

/* Container da mensagem e hora da mensagem do outro */
.LadoOutraPessoa div {
    background-color: var(--green);
    color: white;
    padding: 0.8em;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 1em;
}

/* Hora da mensagem do outro */
.LadoOutraPessoa div strong {
    align-self: self-end;
    font-size: 10px;
    font-weight: bold;
    font-style: italic;
}

/* Container de cada mensagem de quem envia */
.LadoPessoaQueEnvia {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

/* Container da mensagem e hora da mensagem de quem envia */
.LadoPessoaQueEnvia div {
    background-color: var(--pink);
    color: white;
    padding: 0.8em;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 1em;
}

/* Hora da mensagem de quem envia */
.LadoPessoaQueEnvia div strong {
    align-self: self-end;
    font-size: 10px;
    font-weight: bold;
    font-style: italic;
}

/* Junta os containers do final, para manterem sempre no final da página */
.NovoChat article {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
}

/* Valor combinado */
.NovoChat aside {
    background-color: lightgray;
    align-self: self-start;
    padding: 0em 1em 0em;
    width: 50%;
    clip-path: polygon(0 0, 92% 0, 100% 100%, 0% 100%);
}
/* Container do conteúdo Valor combinadO */
.NovoChat aside div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.6em;
    gap: 1em;
    justify-content: space-evenly;
}

/* Input para definir valor */
.NovoChat aside input {
    border-radius: 20px;
    padding: 0.3em;
}

/* Texto valor combinado */
.NovoChat aside div p {
    font-weight: bold;
    font-style: italic;
    font-size: 24px;
}

/* Botão do valor combinado */
.NovoChat aside div button {
    background-color: black;
    color: lightgray;
    padding: 0.4em 1em;
    border-radius: 20px;
    font-size: 20px;
}

/* Botão do valor combinado ao passar o mouse */
.NovoChat aside div button:hover {
    background-color: #3C3D37;
    color: lightgray;

}

/* Lado de quem envia a mensagem */
.NovoChat footer {
    width: 100%;
    margin-top: auto;
}

/* Container do lado de quem envia */
.NovoChat footer div {
    display: flex;
    align-items: center;
    background-color: var(--pink);
    width: 100%;
    padding: 0.6em;
    gap: 1em;
    justify-content: space-evenly;
}

/* Input onde escreve a mensagem */
.NovoChat footer div input {
    width: 92%;
    border-radius: 20px;
    border: none;
    height: 40px;
    padding: 0.4em;
}

/* Botão de enviar mensagem */
.NovoChat footer div button {
    background-color: var(--pink);
    border: none;
    color: white;
}

/* Botão de enviar mensagem ao passar o mouse */
.NovoChat footer div button:hover {
    color: lightgray;
}

/* Responsivo */
@media screen and (max-width: 1300px) {
    .NovoChat aside {
        width: 60%;
    }
}

@media screen and (max-width: 900px) {
    .NovoChat aside {
        width: 90%;
    }

}

@media screen and (max-width: 700px) {
    .NovoChat aside {
        width: 95%;
    }

    .NovoChat aside div p {
        font-size: 20px;
    }

    .NovoChat aside div button {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px) {
    .ContainerAceitar {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 550px) {
    .NovoChat aside div p {
        font-size: 15px;
    }

    .NovoChat aside div button {
        font-size: 13px;
    }
}

@media screen and (max-width: 435px) {
    .NovoChat aside div p {
        font-size: 12px;
    }

    .NovoChat aside div button {
        font-size: 12px;
    }
}

@media screen and (max-width: 420px) {
    .NovoChat aside {
        width: 100%;
    }

    .NovoChat aside div p {
        font-size: 10px;
    }

    .NovoChat aside div button {
        font-size: 10px;
    }

    .NovoChat aside input {
        width: 40%;
    }

    .NovoChat nav div img {        
        margin-left: 0.1em;
    }

    .NovoChat nav div p,
    .NovoChat nav div:nth-child(2) div label {
       font-size: 12px;
    }
}

