/* Container principal */
.Atualizacao {
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Titulo principal */
.Atualizacao h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
}

/* Bem vindo */
.Atualizacao p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}
/* Log */
.Atualizacao h3 {
    margin: 1em 0;
    font-size: 22px;
    text-align: center;
}

@media screen and (max-width: 1360px) {       
    .Atualizacao {
        padding: 1em 0.5em;  
    }  
}

