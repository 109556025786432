/* Container principal */
.CriarNotaFiscalCPF {       
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;    
}
/* Titulo principal */
.CriarNotaFiscalCPF h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
}
/* Texto abaixo do título */
.CriarNotaFiscalCPF h3 {
    text-align: center;
    font-size: 24px;
    color: var(--dark-blue);
}
/* Link para ir até perfil dentro do texto abaixo do título */
.CriarNotaFiscalCPF h3 a {
    color: var(--pink);
}
/* Formulário */
.CriarNotaFiscalCPF nav main {
    width: auto;   
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    /* padding: 0 1.4em; */
    border-radius: 00px 0px 30px 30px; 
    display: flex;
    flex-direction: column;       
}
/* Cabeçalho */
.CriarNotaFiscalCPF nav header {
    background-color: #782A7C;   
    border-radius: 30px 22px 0px 0px;   
}
/* Texto dentro do cabeçalho */
.CriarNotaFiscalCPF nav header p {
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
    padding: 0.2em 0.2em 0.2em 0.8em;    
}
/* Container do texto */
.CriarNotaFiscalCPF nav div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px; 
    margin: 1em;
    
    
}
/* Container do ícone */
.CriarNotaFiscalCPF nav div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.CriarNotaFiscalCPF nav div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;  
    background-color: lightgray;;
}
/* Container de idade e data de emissão */
.CriarNotaFiscalCPF section {
    justify-content: space-around;
    display: flex;
}
/* Container do endereço e cnpj */
.CriarNotaFiscalCPF address {
    margin: 1em 0;
}
/* Texto do container de endereço e cnpj */
.CriarNotaFiscalCPF address p {
    text-align: center;
    font-weight: 500;
    font-style: italic;
}
/* Botão de enviar fatura */
.CriarNotaFiscalCPF section button {
    width: 200px;
    margin-bottom: 1em;
}

.CriarNotaFiscalCPF nav main a {
    margin: 0.8em auto;
    width: 200px;
}

.FormEnviar {
    border-radius: 20px;      
    width: auto;
    display: flex;
    flex-direction: column !important;  
    
    align-items: center;
}

.CriarNotaFiscalCPF span {
    font-size: 18px;
    color: var(--pink);
}

.FormEnviar button {
    width: 200px;
    margin-bottom: 1em;
}

.FormEnviar label {
    display: none;
}

@media screen and (max-width: 1360px) {       
    .CriarNotaFiscalCPF {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 550px) {       
    .CriarNotaFiscalCPF section {
        flex-direction: column;        
    }  
    .CriarNotaFiscalCPF section button {
        margin: 0.8em auto;
    }
}


