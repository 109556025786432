/* Container da sessao inteira */
.Remuneracao {
    margin: 3em 0;
    display: flex;
    flex-direction: column;    
    box-sizing: border-box;    
}
/* Titulo da sessao */
.Remuneracao h2 {
    color: var(--dark-blue);
    font-weight: bold;
    text-align: center;
    font-size: 32px;
}
/* Container unico de cada item */
.Remuneracao section {
    display: flex;
    padding: 5em;    
    box-sizing: border-box;    
}
/* Container que fica o texto */
.Remuneracao section aside {
    display: flex;
    gap: 3em;
    flex-direction: column;
    padding: 0 8em;
}
/* Titulo de cada item */
.Remuneracao section aside h3 {
    font-size: 28px;
    text-align: center;
    color: var(--dark-blue);
}
/* Descricao de cada item */
.Remuneracao section aside p {
    font-size: 22px;
}

@media screen and (max-width: 1200px) {       
    .Remuneracao section {     
        flex-direction: column;  
        align-items: center;      
    }    
}

@media screen and (max-width: 1200px) {       
    .Remuneracao section aside {     
        padding: 0 1em;    
    }    
}

@media screen and (max-width: 650px) {  
    .Remuneracao {     
        margin: 1em 0;
    }    
    .Remuneracao section {
        padding: 3em 1em;
    }   
    .Remuneracao section aside {     
        padding: 0em;    
    }   
    .Remuneracao section aside p {
        font-size: 18px;
    } 
    .Remuneracao section div img {
        width: 100%;    
    }
}

