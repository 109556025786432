.CheckBox {
    font-size: 18px !important;
    display: flex !important; /* Alinha o checkbox e o texto na mesma linha */
    align-items: center !important;
    gap: 0.2em !important; /* Espaçamento entre o checkbox e o texto */
}

.CheckBox input {
    margin: 0 !important;
}

.CheckBox:hover {
    cursor: pointer;
}