/* Container principal */
.InfluAcabaramTrabalho {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column;   
    color: var(--white);
    font-size: 18px;
}
/* Título */
.H3 {
    text-align: center;
    font-size: 26px;
    margin: 2em; 
    font-weight: bold;
    color: var(--pink);
}
/* Enfâse */
.InfluAcabaramTrabalho strong {    
    font-weight: bold;
    color: #D0BFFF;
}
/* Container do radio */
.Radio {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
}
/* Parágrafos */
.InfluAcabaramTrabalho p {
    margin: 0.8em 0em;    
}
/* Lista */
.InfluAcabaramTrabalho ul {
    list-style: none;    
}
/* Item da lista */
.InfluAcabaramTrabalho li {
    margin-left: 1.5em;
}

.InfluAcabaramTrabalho button {
    margin-top: 1.5em;
    width: 170px;
    display: flex;
    gap: 0.5em;
    
}
