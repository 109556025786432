/* Container principal */
.RedesSociais {
    padding: 1em 2em;    
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.RedesSociais h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* Formulário */
.RedesSociais form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
}
/* Sub títulos dentro do formulário */
.RedesSociais form h3 {
    color: #D0BFFF;
    font-size: 18px;   
    margin-bottom: 0.5em; 
}
/* Muda somente o segundo sub título */
.RedesSociais form h3:nth-of-type(2) {   
    margin-top: 1em;   
}
/* Botão de criar nova rede social */
.RedesSociais form svg {
    color: var(--white);
    
}
/* Botão de submit */
.RedesSociais form button {
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 1em;
}
/* ícone do botão de submit */
.RedesSociais form button svg {
    color: var(--green);
    margin: 0;
    padding: 0;
}
/* Passar o mouse no botão de criar nova rede social */
.RedesSociais form svg:hover {
    cursor: pointer;
    color: #D0BFFF;
}

@media screen and (max-width: 1360px) {       
    .RedesSociais {
        padding: 1em 0.5em;
    }  
}

@media screen and (max-width: 470px) {       
    .RedesSociais form {
        padding: 36px 36px;
    }  
}
  
