/* Título principal */
.AreaTexto label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);
}
/* Botões do text área */
.AreaTexto button {
    background-color: var(--white) !important;
}

