/* Container Principal */
.PlanoISM {
    margin: 0.5em 0;    
    width: auto;
}
/* Titulo do campo */
.PlanoISM label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);
}
/* Container pequeno do icone */
.PlanoISM div {
   background-color: var(--white);
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px;    
    padding: 0.5em;
}

.PlanoISM div ul li {
    list-style: none;
}