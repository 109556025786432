/* Container principal */
.PerfilInfluenciador {
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}
/* Titulo principal */
.PerfilInfluenciador h2 {
    text-align: center;
    font-size: 32px;    
    font-weight: bold;
    color: var(--pink);
}
/* Container do email e senha */
.PerfilInfluenciador section {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;     
}
/* Titulo email */
.PerfilInfluenciador section label {
    display: block;
    /* margin-bottom: 8px; */
    font-size: 22px;
    color: var(--white);
}
/* Botoes  do email e senha*/
.PerfilInfluenciador section button {
    margin: 1em;
}
/* Caso já seja verificado */
.PerfilInfluenciador section p {
    color: var(--lilas);
    margin-top: 0.6em;
}
/* Container do texto */
.PerfilInfluenciador section div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px;  
    margin-top: 0.4em;
    margin-bottom: 0.4em;   
}
/* Container do ícone */
.PerfilInfluenciador section div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.PerfilInfluenciador section div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;      
}
/* Input */
.PerfilInfluenciador section div input {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black; 
}
/* Formulario */
.PerfilInfluenciador form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;    
}
.PerfilInfluenciador form img {
    width: 300px;
    padding: 1em 0;
    margin: 0 auto;
}
/* SubTitulo */
.PerfilInfluenciador form h3 {
    text-align: center;
    font-size: 20px;
    color: var(--lilas);
}
/* Alertas input */
.PerfilInfluenciador form p {
    color: var(--white);
}
/* Linha que separa */
.PerfilInfluenciador form hr {
    color: var(--white);
}
/* Container botões de navegação */
.BotoesNavegação {    
    display: flex;
    gap: 0.5em;
    margin-top: 2em;
}
/* Linha que separa */
.PerfilInfluenciador form hr {
    color: var(--white);
}
/* Container do botao */
.BotoesNavegação button {
    color: var(--white);
    background: var(--dark-blue) !important;
    width: 100%;
    margin-top: 0.5em; 
}
/* Botao quando passa o mouse */
.BotoesNavegação button:hover {
    background-color: var(--pink) !important;
}

.FormPdf button {
    margin-bottom: 1em;
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width: 1360px) {       
    .PerfilInfluenciador {
        padding: 1em 0.5em;  
    }  
}

@media screen and (max-width: 500px) {       
    .PerfilInfluenciador section {
        padding: 36px 18px;        
    }  
    .PerfilInfluenciador form {
        padding: 36px 18px;
    }
}
  
