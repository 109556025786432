/* Container principal */
.LinksInfluencers {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.LinksInfluencers h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.6em;
    color: var(--pink);
}

.StrongCard {
    font-weight: bold;
    color: var(--dark-blue);
}

.LinksInfluencers table tbody tr td a, .LinkCard {
    color: var(--black);
    margin-left: 0.2em;
}

.LinksInfluencers table tbody tr td a:hover, .LinkCard:hover {
    color: var(--indigo);   
    cursor: pointer; 
}

.LinksInfluencers table tbody tr td svg, .IconeCard {
    color: var(--pink);
    margin-left: 0.2em;
}

.LinksInfluencers table tbody tr td svg:hover, .IconeCard:hover {
    color: var(--indigo);   
    cursor: pointer; 
}


@media screen and (max-width: 1360px) {       
    .LinksInfluencers {
        padding: 1em 0.5em; 
    }  
}

