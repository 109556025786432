/* Container geral, o fundo */
.Login {
    background-color: #FFFBF5;
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}
/* Corpo do formulario */
.Login form {    
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column;  
    color: var(--white);   
}
/* Imagem perfil do formulario */
.Login form img {
    height: 130px;
    width: 130px;
    align-self: center;    
}
/* Titulo principal */
.Login form h2 {
    font-size: 28px;
    margin: 1em 0 0.6em 0;
    color: var(--white);
    text-align: center;
}

.Login form aside {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
/* Container do botao */
.Login form aside button{
    color: var(--white);
    background: var(--dark-blue);
    width: 100%;
    margin-top: 0.5em;    
}
/* Chamada Fora do formulario */
.Login h3 {
    font-size: 28px;
    margin: 0.6em 0 0em 0;
    color: var(--pink);
    text-align: center;
}
/* Link */
.Login h4 {
    font-size: 22px;    
    color: var(--dark-blue);
    text-align: center;
}
/* Link quando passa o mouse */
.Login h4:hover {
    cursor: pointer;
    color: indigo;
}
/* Botao quando passa o mouse */
.Login form aside button:hover{    
    background-color: var(--pink) !important;    
}

@media screen and (max-width: 1360px) {       
    .Login {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 450px) {       
    .Login form {
        padding: 36px 36px;
    }  
}