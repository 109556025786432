.SendBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SendBanner h3 {
    color: var(--pink);
    text-align: center;
    font-size: 24px;
    margin: 0.8em;
}

.SendBanner aside {
    display: flex;
    width: auto;
    padding: 0.4em;
}

.SendBanner aside input {
    margin: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.SendBanner button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

/* Estilização da lista de imagens */
.SendBanner div h4 {
    text-align: center;
    color: var(--pink);
    margin-bottom: 0.4em;
}

.imageList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.bannerImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.bannerVideo {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.bannerImage:hover, .bannerVideo:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.SendBanner p {
    color: var(--black) !important;
    font-size: 15px !important;
}

.imageList {
    margin-bottom: 1.2em;
}
