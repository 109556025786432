/* Formulario */
.InfluenciadorFormulario {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
}
/* Alertas input */
.InfluenciadorFormulario p {
    color: var(--white);    
}
/* Botao logar */
.InfluenciadorFormulario div button {
    color: var(--white);
    background: var(--dark-blue) !important;
    width: 100%;
    margin-top: 0.5em;
}
/* Quando passa o mouse no botao logar */
.InfluenciadorFormulario div button:hover{    
    background-color: var(--pink) !important;    
}
/* Container botões de navegação */
.BotoesNavegação {    
    display: flex;
    gap: 0.5em;
    margin-top: 2em;
}

@media screen and (max-width: 450px) {       
    .InfluenciadorFormulario {
        padding: 36px 36px;
    }  
}