/* Centraliza o container do alerta */
.Alertas div {
    display: flex;
    align-items: center;
    padding: 0;       
}
/* ajeita o botao */
.Alertas div button {    
    margin: 0 !important;
}
