/* Cor padrão */
.LinkTable, .LinkIconEdit {
    color: var(--black);
}
/* Ao passar o mouse */
.LinkTable:hover, .LinkIconEdit:hover {
    color: var(--pink);
}
/* Container da paginação */
.Pagination {
    display: flex;
    justify-content: center;
}

.aTable {
    color: black;
}

.aTable:hover {
    color: var(--pink);
}

.IconeAdicionarBanners {
    color: darkorchid !important;
}

.IconeAdicionarBanners:hover {
    color: var(--pink);
}

.BotaoCopiarCupom {
    color: black;
}

.BotaoCopiarCupom:hover {
    cursor: pointer;
    color: var(--pink) !important;
}
