/* Container principal */
.CriarCampanha {
    display: flex;
    flex-direction: column;
    align-items: center;        
    padding: 1em 2em;
    gap: 0.5em;
}
/* Titulo principal */
.CriarCampanha h2 {
    font-size: 32px;
    font-weight: bold;
    color: var(--pink);
    text-align: center;
}

.CriarCampanha label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);
}

.CheckBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Divide em 2 colunas iguais */
    gap: 1rem; /* Espaçamento entre os elementos */
}
/* Formulário */
.CriarCampanha form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
}
/* Container dos alertas que tem dois <p> */
.ContainerAlertas {
    display: flex;
    flex-direction: column;
    color: var(--white);
}
/* Alertas sozinhos */
.CriarCampanha form span {
    color: var(--white);
}
/* Container de categorias */
.Categorias label, .Objetivos label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);   
    margin-top: 0.5em; 
}
/* Lista de categorias */
.Categorias ul, .Objetivos ul {
    display: flex;
    justify-content: space-around;
    background-color: var(--white);
    height: auto;
    border-radius: 0 10px 10px 0;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4em;
    /* max-width: 345px; */
    padding: 0.5em;
    margin-bottom: 1em;
    min-height: 53px;    
    width: 100%;
}
/* item da lista de categorias */
.Categorias ul li, .Objetivos ul li {
    display: flex;
    align-items: center;
}
/* Ícone de excluir */
.Categorias ul li svg, .Objetivos ul li svg {
    color: red;
}
/* Ícone de excluir */
.Categorias ul li svg:hover,  .Objetivos ul li svg:hover {
    cursor: pointer;
}

/* Container das idades */
.CriarCampanha form div section {
    display: flex;
    gap: 1em;
}
/* Botão */
.CriarCampanha form button {
    background-color: var(--dark-blue);    
    margin-top: 1em;
}
/* Botão ao passar o mouse */
.CriarCampanha form button:hover {
    background-color: var(--pink);
}

@media screen and (max-width: 500px) {       
    .CriarCampanha form {
        padding: 36px 18px;
    }  
}

@media screen and (max-width: 1360px) {       
    .CriarCampanha {
        padding: 1em 0.5em; 
    }  
}

