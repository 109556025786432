/* Container principal */
.GerenciamentoDeInfluenciadores {    
    display: flex;
    flex-direction: column;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    padding: 1em 1em 0.5em 1em;
    border-radius: 10px;
    justify-content: space-around;

    
    height: 340px;
    width: 320px;
}
/* Titulo e rodape do card */
.GerenciamentoDeInfluenciadores h3 {
    font-size: 21px;
    text-align: center;
    color: var(--dark-blue);
    margin-bottom: 0.5em;    
    font-weight: bold;
}
/* Total */
.GerenciamentoDeInfluenciadores p {
    text-align: center;
}
/* Sensacao de crescer ao passar o mouse */
.GerenciamentoDeInfluenciadores:hover {
    cursor: pointer;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.025);
    transition: 0.18s;    
}