.MissOfParameters div {    
    justify-content: center;
}

.MissOfParameters a {
    color: var(--dark-blue);
    font-weight: bold;
}

.MissOfParameters aside {    
    display: flex;
    justify-content: center;
}