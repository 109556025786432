/* Container Principal */
.Cadastrar {
    padding: 1em 2em;    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}
/* Container dos botoes de escolha de conta */
.Cadastrar nav {    
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo de perguntar a escolha */
.Cadastrar nav h2 {
    font-size: 28px;
    color: var(--dark-blue);
    margin-bottom: 1em;
}
/* Container dos botoes */
.Cadastrar nav div {    
    display: flex;
    gap: 1em;
}

@media screen and (max-width: 1360px) {       
    .Cadastrar {
        padding: 1em 0.5em; 
    }  
}


