/* Container principal */
.Parceiros {
    background-image: linear-gradient(to right, var(--indigo), var(--dark-blue));
    padding: 4em;
    display: flex;
    margin: 1em 0;
    box-sizing: border-box;
}
/* Container da imagem */
.Parceiros div {
    display: flex;
    width: 50%;
    flex-wrap: wrap; 
    justify-content: space-around;   
    gap: 0.5em;
} 
/* Container do texto */
.Parceiros aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    color: var(--white);
    gap: 0.5em;
}
/* Titulo */
.Parceiros aside h2 {    
    font-size: 36px;
    font-weight: bold;
}
/* Efeito de aumentar ao passar o mouse na imagem */
.Parceiros div img:hover {    
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.025);
    transition: 0.18s;
    cursor: pointer;
}

@media screen and (max-width: 700px) {       
    .Parceiros {     
        flex-direction: column-reverse;
        align-items: center;
        gap: 2em;
    }    
}

