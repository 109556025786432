/* Layout de tabela padrão em telas grandes */
.table-responsive {
    overflow-x: auto;
}

@media (max-width: 768px) {
    /* Esconder o cabeçalho da tabela */
    .TabelaTransacao table thead {
        display: none;
    }

    /* Transformar cada linha em um "cartão" */
    .TabelaTransacao table tbody tr {
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
        margin-bottom: 10px;
        padding: 10px;
    }

    /* Cada célula exibe o label e o conteúdo */
    .TabelaTransacao table tbody tr td {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        text-align: left;
        border: none;
    }

    /* Estilo para o label */
    .TabelaTransacao table tbody tr td::before {
        content: attr(data-label);
        font-weight: bold;
        text-align: left;
        padding-right: 10px;
        flex-basis: 40%;
    }
}