/* Container principal */
.RecusadosInfluenciador {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.RecusadosInfluenciador h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.RecusadosInfluenciador a {
    color: var(--dark-blue);
}
.RecusadosInfluenciador aside {    
    display: flex;
    justify-content: space-around;
    width: auto;
    align-items: center;
    gap: 1em;
}

/* Título de pesquisa e tabela */
.RecusadosInfluenciador h6 {
    margin: 1em 0;
    text-align: center;
    font-size: 26px;
}

.RecusadosInfluenciador aside div {
    margin: 0 !important;
}
/* Container maior */
.RecusadosInfluenciador div {
    
    margin: 1em !important;
}
.RecusadosInfluenciador div label {
    display: none;
}

.RecusadosInfluenciador nav {    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;    
}

.RecusadosInfluenciador nav div div {
    border: solid black 1px;
}

@media screen and (max-width: 850px) {       
    .RecusadosInfluenciador aside {
        flex-direction: column;
        gap: 1em;
    }     
}

@media screen and (max-width: 700px) {       
    .RecusadosInfluenciador nav {
        flex-direction: column;
    }     
}

@media screen and (max-width: 550px) {       
    .CelulaBotoes {
        width: 300px;
    }     
}




