/* Container principal */
.InicioAnunciante {       
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.InicioAnunciante h2 {
    text-align: center;
    font-size: 32px;
}
/* Bem vindo */
.InicioAnunciante p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}
/* Span */
.InicioAnunciante span {
    text-align: center;
    color: var(--pink);
    font-size: 24px;
}
/* Span */
.InicioAnunciante span a {
    text-decoration: none;
    color: var(--dark-blue);
}
/* Aqui estao seus dados */
.InicioAnunciante h5 {
    margin-bottom: 0.2em;  
    text-align: center;  
    font-size: 22px;
}
/* Informações do plano */
.InicioAnunciante h6 {
    text-align: center;  
    font-size: 22px;
    margin-bottom: 0.5em;
}
/* Nome do plano e clicando aqui para acessar assinatura */
.InicioAnunciante h6 strong {
    color: var(--pink);
}
/* Nome do plano e clicando aqui para acessar assinatura ao passar o mouse */
.InicioAnunciante h6 strong:hover {
    color: var(--green);
    cursor: pointer;
}
.InicioAnunciante aside {
    margin-bottom: 0.8em; 
}
/* Container que envolve os cards */
.InicioAnunciante section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2em;    
}

@media screen and (max-width: 1360px) {       
    .InicioAnunciante {
        padding: 1em 0.5em; 
    }  
}

