/* Container principal */
.MinhasCampanhas {
    padding: 1em 2em;     
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.MinhasCampanhas h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}

/* Formulário do filtro */
.MinhasCampanhas form  {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;     
    margin-top: 1em;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.6em;
    margin-bottom: 3em;
}
/* Personaliza o botão do filtro */
.MinhasCampanhas form button {
    background-color: var(--white) !important;
    height: 60% !important;
    margin-top: 2.3em;
}
/* Poem a mesma largura nas entradas de texto */
.MinhasCampanhas form select, .MinhasCampanhas form input {
    width: 230px;
}
/* Container dos botões */
.divBotoesModal {
    margin-top: 1em;
}
/* Icone de campanha invisível */
.IconeInvisivel {
    cursor: pointer;
    color: var(--pink);
}
.divBotoesModal button {
    margin-right: 0.6em;
}
/* ícone editar */
.IconeEditar {
    color: var(--dark-blue) 
}
.IconeEditar:hover {
    cursor: pointer;
    color: var(--lilas); 
}
/* ícone finalizar */
.IconeFinalizar {
    color: red;
}
.IconeFinalizar:hover {
    cursor: pointer;
    color: var(--lilas); 
}
.IconeFinalizar:hover {
    cursor: pointer;
    color: var(--lilas);
}
/* ícone ativar campanha */
.IconeAtivar {
    color: #40A2E3;
}
/* Icone de campanha invisivel */
.tooltip {
    font-size: 18px; /* Ajuste o tamanho da fonte conforme necessário */
  }
.IconeAtivar:hover {
    cursor: pointer;
    color: var(--lilas) !important;
}
/* Terceiro ícone, estatísticas */
.IconeEstatistica {
    color: #FFA447 !important;
}
.IconeEstatistica:hover {
    cursor: pointer;
    color: var(--lilas) !important;
}
/* ícone do saldo */
.IconeSaldo {
    color: #68B870;
}
.IconeSaldo:hover {
    cursor: pointer;
    color: var(--lilas);
}
/* Mensagem saldo */
.MensagemSaldo {
    color: red;
    margin-top: 0.6em;
}
/* Alerta de saldo insuficiente */
.AlertaSaldo {
    color: red;
    margin-top: 0.6em;
}

.IconeNovosObjetivos {
    color: var(--pink);
}

.IconeNovosObjetivos:hover {
    color: #AA5486;
}

.MinhasCampanhas table td a {
    color: black;
}

.MinhasCampanhas table td a:hover {
    color: var(--pink);
}

.IconeAdicionarBanners {
    color: var(--pink) !important;
}

.IconeAdicionarBanners:hover, .iconeAdministrarCupons:hover {
    color: #AA5486 !important;
}

.iconeAdministrarCupons {
    color: darkgoldenrod !important;
}

@media screen and (max-width: 1360px) {       
    .MinhasCampanhas {
        padding: 1em 0.5em;  
    }  
}

@media screen and (max-width: 800px) {       
    .MinhasCampanhas form {
        flex-direction: column;        
    }  
}

@media screen and (max-width: 580px) {       
    .MinhasCampanhas form {
        padding: 36px 32px;
    }  
}

