/* Card principal */
.CardCarteira, .CardCarteiraSaldo, .CardCarteiraExtrato {
    width: 100%;
    max-width: 600px;
    margin: 1em auto;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    overflow: hidden;
}

/* Cabeçalho */
.CardCarteira header, .CardCarteiraSaldo header, .CardCarteiraExtrato header {
    background-color: #782A7C;
    color: var(--white);
    font-weight: 500;
    font-size: 24px;
    padding: 0.5em 1em;
    border-radius: 30px 30px 0 0;
}

/* Cabeçalho */
.CardCarteira header p, .CardCarteiraSaldo header p, .CardCarteiraExtrato header p {
    color: var(--white);
}

/* Conteúdo em colunas */
.CardCarteira aside, .CardCarteiraSaldo aside, .CardCarteiraExtrato aside {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.CardCarteira aside p, .CardCarteiraSaldo aside p, .CardCarteiraExtrato aside p {
    font-size: 24px;
    color: #782A7C;
    margin: 0.4em 0;
}

/* Configurações do section */
.PrimeiroSection, .PrimeiroSectionSaldo {
    font-style: italic;
    font-weight: 500;
}

.SegundoSection {
    font-weight: 700;
}

.SegundoSection p {
    display: flex;
    align-items: center;
    gap: 0.4em;
}

/* Icones e botões */
.SegundoSection p svg {
    color: #68B870;
    cursor: pointer;
}

.SegundoSection p svg:hover {
    color: #B8B8B8;
}

.CardCarteira nav, .CardCarteiraSaldo nav, .CardCarteiraExtrato nav {
    display: flex;
    justify-content: center;
    gap: 1em;
    padding: 0.8em;
}

.CardCarteira nav button, .CardCarteiraSaldo nav button, .CardCarteiraExtrato nav button {
    background-color: #68B870;
    color: white;
    border-radius: 60px;
    padding: 0.5em 1.5em;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.4em;
}

.Refresh {
    background-color: white;
    color: var(--dark-blue);
}

.InputModal {
    width: 100%;
    margin-top: 0.4em;
}

/* Alertas */
.AlertaSaldo {
    color: red;
    margin-top: 0.6em;
}

.Verde {
    color: #68B870 !important;
}

.Vermelho {
    color: red !important;
}

/* Botões do modal */
.divBotoesModal {
    margin-top: 1em;
}

.divBotoesModal button {
    margin-right: 0.6em;
}
/* Altera somente do ultimo card */
.CardCarteiraExtrato aside {
    align-items: center;
}

.PrimeiroSectionExtrato {
    width: 40%;
}

/* Responsivo */
@media screen and (max-width: 650px) {
    .CardCarteira, .CardCarteiraSaldo, .CardCarteiraExtrato {
        max-width: 100%;
    }

    .CardCarteira aside p, .CardCarteiraSaldo aside p, .CardCarteiraExtrato aside p {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .PrimeiroSectionExtrato {
        width: 30%;
    }
}

@media screen and (max-width: 450px) {
    .CardCarteiraSaldo aside {
        align-items: center;
    }

    .PrimeiroSectionSaldo {
        width: 40%;
    }
    .PrimeiroSectionExtrato {
        width: 20%;
    }    
    .CardCarteiraExtrato aside {
        padding: 0.4em;
        
    }
}

@media screen and (max-width: 400px) {
    .CardCarteiraExtrato aside p {
        /* font-size: 20px; */
    }
}