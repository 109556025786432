/* Container principal */
.CampanhasPerformanceModerador {       
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center; 
}

/* Título principal */
.CampanhasPerformanceModerador h2 {
    text-align: center;
    font-size: 32px;
}

/* Bem-vindo */
.CampanhasPerformanceModerador p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

/* Container dos botões de filtro */
.CampanhasPerformanceModerador nav {
    margin-top: 2em;
    display: flex;
    gap: 1em;
}

/* Tabela (exibida apenas em telas grandes) */
.CampanhasPerformanceModerador table {
    margin-top: 1em !important;
    width: auto;
    display: table;
}

/* Link para ir até detalhes de campanha performance */
.CampanhasPerformanceModerador table a {
    color: black;
}

/* Link para ir até detalhes de campanha performance ao passar o mouse */
.CampanhasPerformanceModerador table a:hover {
    color: var(--pink);
}

/* Container de busca de API */
.CampanhasPerformanceModerador aside {
    background-color: lightgray;
    display: flex;
    padding: 1em;
    gap: 1em;
    margin-top: 2em;
}

/* Título do container de busca de API */
.CampanhasPerformanceModerador aside label {
    color: black;
}

/* Botão de buscar na API */
.CampanhasPerformanceModerador aside button {
    height: 50px;
    margin: auto 0;
}

/* Botão na tabela */
.CampanhasPerformanceModerador table button {
    height: 35.2px;
    margin-right: 0.2em;
    margin-left: 0.4em;
}

/* Container dos botões */
.divBotoesModal {
    margin-top: 0.8em;
}

/* Botões */
.divBotoesModal button {
    margin-right: 0.2em;
}

/* Texto de busca */
.Busca {
    margin-top: 1.4em;
}

/* Container do status */
.divStatus {
    margin-top: 2em;
}

/* Ícone ativar campanha */
.IconeAtivar {
    color: #40A2E3;
}

/* Ícone finalizar */
.IconeFinalizar {
    color: red;
}

/* Ícones ao passar o mouse */
.IconeAtivar:hover,
.IconeFinalizar:hover {
    cursor: pointer;
}

/* Cards para telas menores */
.cards {
    display: none;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.card-title {
    font-size: 1.2em;
    font-weight: bold;
}

.card-text {
    margin: 0.5em 0;
}

/* Botões de ações no card */
.card-actions {
    display: flex;
    gap: 0.5em;
    margin-top: 1em;
}

.card-actions button {
    padding: 0.5em 1em;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

/* Botão Editar */
.card-actions .btn-editar {
    background-color: #4CAF50;
    color: white;
}

.card-actions .btn-editar:hover {
    background-color: #45a049;
}

/* Botão Copiar Código */
.card-actions .btn-copiar {
    background-color: #008CBA;
    color: white;
}

.card-actions .btn-copiar:hover {
    background-color: #007bb5;
}

.IconeNovosObjetivos {
    color: orange;
}

.IconeNovosObjetivos:hover {
    color: orangered;
}

.IconeLinks {
    color: var(--indigo);
}

.IconeLinks:hover {
    color: #007bb5;
}

.IconeAdicionarBanners {
    color: darkorchid !important;
}


/* Estilos responsivos */
@media screen and (max-width: 1360px) {       
    .CampanhasPerformanceModerador {
        padding: 1em 0.5em;
    }  
}

@media screen and (max-width: 1000px) {
    /* Esconde a tabela e exibe os cards em telas menores */
    .CampanhasPerformanceModerador table {
        display: none;
    }

    .cards {
        display: flex;
    }
}
