/* Container principal */
.LeituraInfluenciador {       
    padding: 1em 2em;      
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.LeituraInfluenciador h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.6em;
}
/* Container do usuario */
.LeituraInfluenciador form {
    border-radius: 20px;
    padding: 18px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;  
    gap: 2em; 
}
/* Foto de perfil */
.LeituraInfluenciador form img {    
    width: 250px;
    height: auto;
    border-radius: 150px;
    align-self: center;
}

/* Container do texto */
.LeituraInfluenciador form div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px; 
}
/* Container do ícone */
.LeituraInfluenciador form div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.LeituraInfluenciador form div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;  
}

.LeituraInfluenciador form div label strong {
    font-weight: bold;
}

.LeituraInfluenciador form p {
    color: var(--lilas);
}

.MidiaKit {
    margin-top: 1.5em;
}

@media screen and (max-width: 1360px) {       
    .LeituraInfluenciador {
        padding: 1em 0.5em; 
    }  
}


