/* Container do título e do botão switch */
.CampoSwitch {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}
/* Texto do botão switch */
.CampoSwitch label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: #D0BFFF;
}