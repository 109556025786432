/* Container de cada card */
.CardVantagens {
    background-color: var(--green);
    border-radius: 15px;
    width: 400px;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    box-sizing: border-box;
    color: var(--white);
}
/* Imagem pequena, a logo */
.CardVantagens ul li img {
    width: 3em;
    height: auto;
}
/* Titulo do card e descricao */
.CardVantagens h2, .CardVantagens h3 {
    text-align: center;
}
/* Titulo */
.CardVantagens h2 {
    font-size: 26px;
}
/* Cada item da lista */
.CardVantagens ul li {
    display: flex;
    align-items: center;    
}
/* Ultima frase */
.CardVantagens span {
    text-align: center;    
}

.CardVantagens:hover {
    cursor: pointer;
    background-color: var(--dark-blue);    
    color: var(--white);
}

@media screen and (max-width: 500px) {       
    .CardVantagens {
        width: 300px;
    }  
 }
 






