/* Container principal */
.AdicionarBanners {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.AdicionarBanners h2 {
    text-align: center;
    font-size: 32px;
}
/* Bem vindo */
.AdicionarBanners p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 1360px) {       
    .AdicionarBanners {
        padding: 1em 0.5em; 
    }  
}

