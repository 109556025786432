/* Container principal */
.AdicionarSaldo {       
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.AdicionarSaldo h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
    font-weight: bold;
}

.AdicionarSaldo input {
    background-color: var(--lilas);
}

.AdicionarSaldo button {
    margin-bottom: 1em;
}

@media screen and (max-width: 1360px) {       
    .AdicionarSaldo {
        padding: 1em 0.5em; 
    }  
}


