/* Container principal */
.UsuarioNaoAceito {       
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.UsuarioNaoAceito p {    
    font-size: 18px;
}

@media screen and (max-width: 1360px) {       
    .UsuarioNaoAceito {
        padding: 1em 0.5em;  
    }  
}

