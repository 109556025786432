/* Container principal */
.EnviarTrabalho {
       
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.EnviarTrabalho h2 {
    text-align: center;
    font-size: 32px;    
    font-weight: bold;
    color: var(--pink);    
}
/* SubTítulo */
.EnviarTrabalho h3 {
    text-align: center;
    font-size: 26px;
    color: var(--dark-blue);
    margin-top: 1.2em;
}
/* Formulário para enviar os arquivos */
.EnviarTrabalho form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;  
    margin-top: 2em;
}
/* Lista de arquivos enviados */
.EnviarTrabalho ul {
    list-style: none;    
    margin-top: 1em;
}
/* Item da lista */
.EnviarTrabalho ul li:hover, .EnviarTrabalho ul li label:hover {
    cursor: pointer;
    color: var(--pink);
}