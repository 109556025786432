/* Container principal */
.EstatisticasDeCampanhaModerador {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.EstatisticasDeCampanhaModerador h2 {
    text-align: center;
    font-size: 32px;
}
/* Titulo principal */
.EstatisticasDeCampanhaModerador p {
    text-align: center;
    font-size: 26px;
    margin-top: 1em;
}
/* Tabela */
.EstatisticasDeCampanhaModerador table {
    margin-top: 1.5em;
    width: auto;
}
/* ícone estatística */
.EstatisticasDeCampanhaModerador table tbody tr td a svg {
    color: orange;
}
/* ícone estatística ao passar o mouse */
.EstatisticasDeCampanhaModerador table tbody tr td a svg:hover {
    color: var(--pink);
}


@media screen and (max-width: 1360px) {       
    .EstatisticasDeCampanhaModerador {
        padding: 1em 0.5em; 
    }  
}

