/* Container principal */
.TabelaParaCopiar {       
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.TabelaParaCopiar h2 {
    text-align: center;
    font-size: 32px;
}