/* Container principal */
.DetalhesCampanha {
    padding: 1em 2em;     
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.DetalhesCampanha h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* Imagem da campanha */
.DetalhesCampanha img {
    width: 460px;
    height: auto;
}
/* Container de formulário */
.DetalhesCampanha form {
    border-radius: 20px;
    padding: 36px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;  
    gap: 2em; 
}
.Botoes {
    border-radius: 20px;
    padding: 36px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;  
    gap: 2em;
}
.Botoes h3 {
    color: var(--white);
    font-size: 24px;
    text-align: center;
}
/* Subtítulo */
.DetalhesCampanha form h3 {
    color: var(--white);
    font-size: 24px;
    text-align: center;
}

.DetalhesCampanha aside {
    border-radius: 20px;
    padding: 30px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;     
    margin-top: 1em;  
    gap: 2em;
}
/* Container do texto */
.DetalhesCampanha form div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px; 
}
/* Container do ícone */
.DetalhesCampanha form div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.DetalhesCampanha form div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;  
}

.DetalhesCampanha form div label strong {
    font-weight: bold;
}
/* Botão copiar link */
.BotaoCopiar {
    color: var(--dark-blue) !important;
}
/* Botão copiar link ao passar o mouse */
.BotaoCopiar:hover {
    cursor: pointer;
    color: var(--pink) !important;
}

.BotaoCopiarCupom:hover {
    cursor: pointer;
    color: var(--pink) !important;
}

.Copiado {
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 1360px) {       
    .DetalhesCampanha {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 480px) {       
    .DetalhesCampanha img {
        width: 350px;        
    }
}

