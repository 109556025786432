/* Container principal */
.EditarImagemCampanhaAnunciante {
    display: flex;
    flex-direction: column;
    align-items: center;        
    padding: 1em 2em; 
    gap: 0.5em;
}
/* Título */
.EditarImagemCampanhaAnunciante h2 {
    font-size: 26px;
    margin-top: 1.5em;
    color: var(--pink);
    font-weight: bold;
}
.EditarImagemCampanhaAnunciante h3 {
    color: var(--white);
    font-size: 18px;
}
/* Formulário */
.EditarImagemCampanhaAnunciante form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
    align-items: center;
    gap: 1em;
}
/* Imagem */
.EditarImagemCampanhaAnunciante form img {
    width: 600px;
    height: auto;
}
/* Container do botão enviar imagem */
.ContainerImagemCampanha {    
    width: 100%;
}
/* Botão enviar imagem */
.ContainerImagemCampanha button {
    width: 100%;
}

@media screen and (max-width: 750px) {       
    .EditarImagemCampanhaAnunciante form img {
        width: 500px;        
    }
}

@media screen and (max-width: 650px) {       
    .EditarImagemCampanhaAnunciante form img {
        width: 400px;        
    }
    .EditarImagemCampanhaAnunciante form {
        padding: 36px 36px;
    }
}

@media screen and (max-width: 500px) {   
    .EditarImagemCampanhaAnunciante form {
        padding: 36px 18px;
    }
}

@media screen and (max-width: 450px) {   
    .EditarImagemCampanhaAnunciante form img {
        width: 300px;        
    }
}

@media screen and (max-width: 1360px) {       
    .EditarImagemCampanhaAnunciante {
        padding: 1em 0.5em; 
    }  
}

