/* Container principal */
.VerPerfilAnunciante {
    padding: 6em 0.5em 2em 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.VerPerfilAnunciante h3 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 1.6em;
}
/* Cor na tabela */
.VerPerfilAnunciante a {
    color: var(--dark-blue);
}
/* Tabela */
.VerPerfilAnunciante table {
    width: auto;
}



