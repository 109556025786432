/* Cada notificação */
.Notificacoes {
    padding: 0.8em;
    border-bottom: solid 1px var(--dark-blue);
}
/* Texto clicável da notificação */
.Notificacoes a {
    color: black;
}
/* Contéudo */
.Notificacoes a p {
    text-align: center;
}
