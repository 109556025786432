/* Container principal */
.AbrirChamadoAnunciante {       
    padding: 1em 2em; 
}
/* Titulo principal */
.AbrirChamadoAnunciante h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
}

@media screen and (max-width: 1360px) {       
    .AbrirChamadoAnunciante {
        padding: 1em 0.5em;  
    }  
}

