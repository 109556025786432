/* Container principal */
.Fatura {
    padding: 1em 2em;    
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.Fatura h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}

@media screen and (max-width: 1360px) {       
    .Fatura {
        padding: 1em 0.5em;  
    }  
}


