/* Container principal */
.Sobre {
    padding: 3.5em;
    display: flex;
    box-sizing: border-box;
    align-items: center;
}

/* Imagem sem estilo, verificar... */

/* Primeira e unica div, imagem esta fora dela */
.Sobre div {
    display: flex;
    gap: 1em;
    flex-direction: column;        
    justify-content: center;
    color: #8B267F;
}

/* Titulo */
.Sobre div h2 {
    font-size: 26px;
}

/* Importante */
.Sobre div strong {
    font-weight: bold;
}

@media screen and (max-width: 750px) {       
    .Sobre {     
        flex-direction: column;
        padding: 0em 3em;
        align-items: center;
    }    
}

@media screen and (max-width: 500px) {       
    .Sobre {     
        flex-direction: column;
        padding: 0em 2em;
    }    
}

