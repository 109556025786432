/* Container principal */
.EditarCampanhaAnunciante {
    display: flex;
    flex-direction: column;
    align-items: center;        
    padding: 1em 2em;  
    gap: 0.5em;
}
/* Titulo principal */
.EditarCampanhaAnunciante h2 {
    font-size: 32px;
    font-weight: bold;
    color: var(--pink);
    text-align: center;
}
/* Formulário */
.EditarCampanhaAnunciante form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
}
/* Container dos alertas que tem dois <p> */
.ContainerAlertas {
    display: flex;
    flex-direction: column;
    color: var(--white);
}
/* Alertas sozinhos */
.EditarCampanhaAnunciante form span {
    color: var(--white);
}
/* Container de categorias */
.Categorias label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);   
    margin-top: 0.5em; 
}
/* Lista de categorias */
.Categorias ul {
    display: flex;
    justify-content: space-around;
    background-color: var(--white);
    height: auto;
    border-radius: 0 10px 10px 0;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4em;
    max-width: 345px;
    padding: 0.5em;
    margin-bottom: 1em;
    min-height: 53px;
}
/* item da lista de categorias */
.Categorias ul li {
    display: flex;
    align-items: center;
}
/* Ícone de excluir */
.Categorias ul li svg {
    color: red;
}
/* Ícone de excluir */
.Categorias ul li svg:hover {
    cursor: pointer;
}
/* Container das idades */
.EditarCampanhaAnunciante form div section {
    display: flex;
    gap: 1em;
}
/* Botão */
.EditarCampanhaAnunciante form button {
    background-color: var(--dark-blue);    
    margin-top: 1em;
}
/* Botão ao passar o mouse */
.EditarCampanhaAnunciante form button:hover {
    background-color: var(--pink);
}

@media screen and (max-width: 500px) {       
    .EditarCampanhaAnunciante form {
        padding: 36px 18px;
    }  
}

@media screen and (max-width: 1360px) {       
    .EditarCampanhaAnunciante {
        padding: 1em 0.5em;  
    }  
}

