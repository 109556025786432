/* Container principal */
.EditarInfluenciadores {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.EditarInfluenciadores h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.EditarInfluenciadores a {
    color: var(--dark-blue);
}

.EditarInfluenciadores aside {    
    display: flex;
    justify-content: space-around;
    width: auto;
    align-items: center;
    gap: 0.8em;
}



/* Título de pesquisa e tabela */
.EditarInfluenciadores h6 {
    margin: 1em 0;
    text-align: center;
    font-size: 26px;
}

.EditarInfluenciadores aside div {
    margin: 0 !important;
}

/* Container maior */
.EditarInfluenciadores div {
    margin: 0.6em;
}
/* container menor */
/* .AceitarInfluenciadores div div {    
    border: solid black 1px;
} */

.EditarInfluenciadores div label {
    display: none;
}

.EditarInfluenciadores nav {    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;    
}

.EditarInfluenciadores nav div div {
    border: solid black 1px;
}

@media screen and (max-width: 850px) {
    .EditarInfluenciadores aside {
        flex-direction: column;
        gap: 1em;
    }
}

@media screen and (max-width: 700px) {
    .EditarInfluenciadores nav {
        flex-direction: column;
    }
}

@media screen and (max-width: 550px) {
    .CelulaBotoes {
        width: 300px;
    }
    
}



