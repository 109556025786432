/* Container principal */
.Chamado {
    margin-top: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Título principal */
.Chamado h3 {
    font-size: 24px;
}
/* Ênfase */
.Chamado h3 strong {
    color: var(--dark-blue);
    font-weight: bold;
}
/* Link */
.Chamado h3 strong a {
    color: var(--dark-blue);
    font-weight: bold;
}
/* Formulário */
.Chamado form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 2em;
}
/* Botão */
.Chamado button {
    color: var(--white);
    background: var(--dark-blue) !important;
    width: 100%;
    margin-top: 0.5em;   
}
/* Botão ao passar o mouse */
.Chamado button:hover {
    background-color: var(--pink) !important;     
}

@media screen and (max-width: 550px) {       
    .Chamado form {
        padding: 36px 6px;
    }

    .Chamado form input, .Chamado form select, .Chamado form textarea {
        width: 250px;
    }
}
