/* Titulo principal do nav */
.navbar-brand {
    color: #D0BFFF !important;
}

/* Manter a mesma cor ao passar o mouse */
.navbar-brand:hover {
    color: #D0BFFF;
}

/* Link ao abrir item do menu */
.dropdown-item a {
    color: var(--black);
}

.linkDrop {
    color: black;
    text-align: center;
}

/* Item da lista ao abrir Item do menu, quando clicado */
.dropdown-item:active {
    background-color: var(--white);
}

/* Ao passar o mouse no link ao abrir item do menu */
.dropdown-item a:hover {
    color: #D0BFFF;
}
/* Ao passar o mouse no limpar */
.limpar:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Imagem do perfil */
.Perfil img {
    width: 45px;
}

@media (min-width: 1000px) {
    .Perfil {
        position: absolute;
        right: 0;
        padding: 0 0.2em;
        /* Ajuste conforme necessário */
    }

    /* Centralizou os itens do menu */
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
        gap: 2em;
        flex-wrap: wrap;
    }

}

