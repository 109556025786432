/* Container principal */
.Faq {    
    padding: 0em 2em 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Botãovoltar */
.FaqBotao {
    padding: 1em 2em;
} 

/* Container do conteudo */
.Faq div {
    background-color: lightgray;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2); 
    border-radius: 20px;
    width: auto;   
    padding: 2em;     
}
/* Titulo principal */
.Faq div h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
    font-weight: bold;
    margin-bottom: 0.5em;
}
/* Lista principal */
.Faq div ul li {
    display: flex;
    align-items: center;
}
/* Parafrago principal da lista */
.Faq div ul li p {
    padding: 1em 0;
}
/* O texto que aparece ao clicar */
.Faq div ul span {
    /* margin-left: 2em;    */
    color: var(--pink);
    padding: 1em 0; 
    text-align: center;
}
/* Ao passar o mouse as listas principais mudar de cor */
.Faq div ul li:hover {
    cursor: pointer;
    color: var(--pink);
}

@media screen and (max-width: 1360px) {       
    .Faq {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 700px) {       
    .Faq {
        flex-direction: column;
    }
    .Faq nav {
        margin-top: 1em;
    }
}

@media screen and (max-width: 500px) {       
    .Faq div {
        padding: 1em;  
    }  
    .FaqBotao {
        padding: 1em 1em;
    }  
}

@media screen and (max-width: 400px) {       
    .Faq div {
        padding: 0.2em;  
    }   
} 
    