/* Titulo principal do nav */
.navbar-brand {
    color: #D0BFFF;
}

/* Manter a mesma cor ao passar o mouse */
.navbar-brand:hover {
    color: #D0BFFF;
}

/* Link ao abrir item do menu */
.dropdown-item a {
    color: var(--black);
}

/* Item da lista ao abrir Item do menu, quando clicado */
.dropdown-item:active {
    background-color: var(--white);
}

/* Ao passar o mouse no link ao abrir item do menu */
.dropdown-item a:hover {
    color: #D0BFFF;
}

/* Ao passar o mouse no link ao abrir item do menu em criar campanha que nao é um link mais */
.dropdown-item:hover {
    color: #D0BFFF;
    cursor: pointer;
}

/* Ao passar o mouse no limpar */
.limpar:hover {
    cursor: pointer;
    color: var(--pink);
}

/* Imagem do perfil */
.Perfil img {
    width: 45px;
}

.nenhumaNot {
    padding: 0.2em;
    text-align: center;
}

.verTodas {
    color: black;
    font-size: 15px;
    text-align: center;    
}

.verTodas:hover {
    color: var(--pink);    
}

.hr {
    margin: 0.2em 0;
}

@media (max-width: 1550px) {
    .navbar-nav {
        gap: 1em !important;
    }
}

@media (max-width: 1420px) {
    .navbar-nav {
        gap: 0 !important;
    }
}

/* @media (max-width: 1300px) {
    .navbar-nav {
        margin-left: 0em !important;
    }
} */

@media (max-width: 1280px) {
    .nav-link {
        font-size: 15px !important;
    }
}

@media (max-width: 1240px) {
    .nav-link {
        font-size: 14px !important;
    }
}

@media (max-width: 1240px) {
    .nav-link {
        font-size: 13.5px !important;
    }
}

@media (max-width: 1180px) {
    .nav-link {
        font-size: 13px !important;
    }
}

/* @media (max-width: 1150px) {
    .nav-link {
        padding-right: 0 !important;
    }
} */

@media (max-width: 1200px) {
    .navbar-brand {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
    .navbar-nav {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }
}

@media (max-width: 1150px) {
    .nav-link {
        font-size: 12.5px !important;
    }
}

@media (max-width: 1120px) {
    .nav-link svg {
        width: 30px !important;
        height: 30px !important;
    }
}

@media (max-width: 1080px) {
    .nav-link svg {
        width: 26px !important;
        height: 26px !important;
    }
}

@media (max-width: 1050px) {
    .nav-link svg {
        width: 24px !important;
        height: 24px !important;
        font-size: 12px !important;
    }
}

@media (max-width: 1030px) {
    .nav-link svg {
        width: 20px !important;
        height: 20px !important;
        font-size: 10.5px !important;
    }
}

/* Quando entrar no menu mobile */
@media (max-width: 900px) {
    .nav-brand {
        font-size: 16px !important;
    }
    .nav-link svg {
        width: 35px !important;
        height: 35px !important;
    }
}



/* @media (min-width: 1000px) {
    .Perfil {
        position: absolute;
        right: 0;
        padding: 0 0.2em;
        
    }

    
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
        gap: 2em;
        flex-wrap: wrap;
    }

} */