/* Rodape */
.Rodape {
    background-color: var(--green);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
}

/* Logo */
.Rodape img {
    height: auto;
    width: 4em;
}

/* As duas listas */
.Rodape ul {
    display: flex;
    gap: 1em;
    align-items: center;    
}

/* Todas as listas */
.Rodape ul li {
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 0.2em;    
}

/* Os 3 primeiros links */
.Rodape ul li a {
    color: var(--white);
}

/* Todas as listas ao passar o mouse*/
.Rodape ul li:hover {
    color: #D0BFFF;
    cursor: pointer;
}

/* Os 3 primeiros links ao passar o mouse*/
.Rodape ul li a:hover {
    color: #D0BFFF;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {       
    .Rodape ul {     
        flex-direction: column;
        padding: 0.5em 0;
    }    
}

@media screen and (max-width: 650px) {       
    .Rodape {     
        flex-direction: column;
    }    
}

