/* Container principal */
.InicioInfluenciador {       
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.InicioInfluenciador h2 {
    text-align: center;
    font-size: 32px;
}
/* Bem vindo */
.InicioInfluenciador p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}
/* Span */
.InicioInfluenciador span {
    text-align: center;
    color: var(--pink);
    font-size: 24px;
}
/* Span */
.InicioInfluenciador span a {
    text-decoration: none;
    color: var(--dark-blue);
}
/* Aqui estao seus dados */
.InicioInfluenciador h5 {
    margin-bottom: 2em;  
    text-align: center;  
    font-size: 22px;
}
/* Container que envolve os cards */
.InicioInfluenciador section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2em;    
}

@media screen and (max-width: 1360px) {       
    .InicioInfluenciador {
        padding: 1em 0.5em;  
    }  
}


