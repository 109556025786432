.CelStatusAceito {
    background-color: #CAE0BC;
    padding: 0.2em 0.6em;
    border-radius: 20px;
    font-weight: bold;
}

.CelStatusEspera {
    background-color: #FFC785;
    padding: 0.2em 0.6em;
    border-radius: 20px;
    font-weight: bold;
}

.CelStatusRejeitado {
    background-color: #FB4141;
    padding: 0.2em 0.6em;
    border-radius: 20px;
    font-weight: bold;
}