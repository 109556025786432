/* Container principal */
.TermosDeUso {
    padding: 0em 2em 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Botãovoltar */
.PoliticaEPrTermosDeUsoBotao {
    padding: 1em 2em;
} 
/* Titulos */
.TermosDeUso h2 {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: var(--pink);
    margin: 1.2em;
}
/* Enfases */
.TermosDeUso p strong {
    font-weight: bold;
    color: var(--dark-blue);
}
/* Links */
.TermosDeUso p a {
    color: var(--dark-blue);
}

@media screen and (max-width: 500px) {    
    .PoliticaEPrTermosDeUsoBotao {
        padding: 1em 1em;
    }  
}
