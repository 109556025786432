/* Container principal */
.VerPerfilAnunciante {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.VerPerfilAnunciante h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.VerPerfilAnunciante a {
    color: var(--dark-blue);
}



