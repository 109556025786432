/* Nav do menu */
.navbar {
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));    
    display: flex;
    align-items: center;
    /* Colar o menu sempre no topo */
    z-index: 999;    
    position: sticky;
    width: 100%;     
}

/* Em volta do icone de menu hamburger clicado e aberto*/
.navbar-toggler {
    color: #D0BFFF;
    background-color: var(--white);
}
/* Logo */
.navbar div a img {
    height: 50px;
    width: auto;
}

/* Cada item do menu */
.nav-link {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: var(--white);
}
/* Item selecionado, onde to no menu */
/* Sem uso no momento */
.active {
    color: #D0BFFF !important; 
}

/* Div dos botoes */
.div-botao {
    display: flex;
    gap: 0.5em;
    margin-left: 1em;
}

/* Botoes, login e registrar */
.btn-light {
    color: var(--pink);
}

/* Cada item do menu ao passar o mouse*/
.nav-link:hover {
    color: #D0BFFF;
}

/* Botoes, login e registrar quando passa o mouse */
.btn-light:hover {
    background-color: #D0BFFF;
    color: var(--white);
}