/* Container principal */
.AdicionarNovosObjetivos {
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Titulo principal */
.AdicionarNovosObjetivos h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
    margin-bottom: 0.4em;
}

.AdicionarNovosObjetivos aside {
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);    
    border-radius: 15px;    
    padding: 1em;
    
}

.AdicionarNovosObjetivos section label {
    margin-bottom: 0.4em;
}

.AdicionarNovosObjetivos aside ul li:nth-of-type(1) svg {
    color: var(--lilas);
}

.AdicionarNovosObjetivos aside ul li:nth-of-type(2) svg {
    color: var(--dark-blue);
}

.AdicionarNovosObjetivos aside ul li:nth-of-type(3) svg {
    color: var(--pink);
}

.AdicionarNovosObjetivos aside ul li:nth-of-type(4) svg {
    color: var(--green);
}

.AdicionarNovosObjetivos aside ul li:nth-of-type(5) svg {
    color: var(--indigo);
}

.AdicionarNovosObjetivos h3 {
    margin: 0em 0.8em 0.8em 0.8em;
    font-size: 28px;
    color: var(--dark-blue);
}

.AdicionarNovosObjetivos ul {
    list-style: none;
}

.AdicionarNovosObjetivos ul li {
    font-size: 24px;
}

.AdicionarNovosObjetivos h4 {
    margin: 0.8em 0;
    font-size: 28px;
    color: var(--dark-blue);
}

.AdicionarNovosObjetivos section {
    margin: 1em;
    border-radius: 20px;
    padding: 18px 32px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column;  
    color: var(--white);
}

.AdicionarNovosObjetivos section label {
    font-size: 26px;
}

.AdicionarNovosObjetivos button {
    margin-top: 0.6em;
    background-color: var(--dark-blue);
    color: var(--white);
    padding: 0.4em;
    font-size: 18px;
    border-radius: 20px;
}

.AdicionarNovosObjetivos button:hover {
    background-color: var(--pink);
}

@media screen and (max-width: 1360px) {
    .AdicionarNovosObjetivos {
        padding: 1em 0.5em;
    }
}