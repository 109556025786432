/* Formulário do filtro */
.FiltroInfluenciador  {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;     
    margin-top: 1em;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.6em;
    margin-bottom: 3em;
}
/* Personaliza o botão do filtro */
.FiltroInfluenciador button {
    background-color: var(--white) !important;
    height: 60% !important;
    margin-top: 2.3em;
}
/* Poem a mesma largura nas entradas de texto */
.FiltroInfluenciador select, .FiltroInfluenciador input {
    width: 230px;
}

@media screen and (max-width: 800px) {       
    .FiltroInfluenciador {
        flex-direction: column;        
    }  
}

@media screen and (max-width: 580px) {       
    .FiltroInfluenciador {
        padding: 36px 32px;
    }  
}