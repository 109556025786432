/* Container Principal */
.CampoTexto {
    margin: 0.5em 0;    
    width: auto;
}
/* Titulo do campo */
.CampoTexto label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);
}
/* Container pequeno do icone */
.CampoTexto div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px;    
}

/* Icone */
.CampoTexto div svg {    
    margin: 0 0.2em;
    color: var(--white);    
}
/* Input */
.CampoTexto input {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;      
}
