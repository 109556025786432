/* Container principal */
.AceitarInfluenciadores {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.AceitarInfluenciadores h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.AceitarInfluenciadores a {
    color: var(--dark-blue);
}

.AceitarInfluenciadores aside {    
    display: flex;
    justify-content: space-around;
    width: auto;
    align-items: center;
    margin: 2em;    
}
/* Título de pesquisa e tabela */
.AceitarInfluenciadores h6 {
    margin: 1em 0;
    text-align: center;
    font-size: 26px;
}

.AceitarInfluenciadores aside div {
    margin: 0 !important;
}

/* Container maior */
.AceitarInfluenciadores div {
    margin: 0.6em;
}
/* container menor */
/* .AceitarInfluenciadores div div {    
    border: solid black 1px;
} */

.AceitarInfluenciadores div label {
    display: none;
}

.AceitarInfluenciadores nav {    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;    
}

.AceitarInfluenciadores nav div div {
    border: solid black 1px;
}

@media screen and (max-width: 850px) {       
    .AceitarInfluenciadores aside {
        flex-direction: column;
        gap: 1em;
    }     
}

@media screen and (max-width: 700px) {       
    .AceitarInfluenciadores nav {
        flex-direction: column;
    }     
}

@media screen and (max-width: 550px) {       
    .CelulaBotoes {
        width: 300px;
    }     
}
