/* Tabela geral */
.table {
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue)) !important;
    color: var(--white) !important;
}
/* Logo da empresa */
.imagem {
    width: 30px;
    height: 30px;
}
/* ****Vefificar para entender, mas usei para personalizar o bootstrap */
.table-light {
    --bs-table-bg:
    --bs-table-striped-bg:
}
/* Lista principal */
.lista {
    padding: 1em;
    font-size: 18px;
}
/* Head da tabela */
.lista li:first-child {
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue)) !important;
}
/* Cada item da lista */
.Itemlista {
    list-style: none;
    padding: 0.5em;
    background-color: #ecedee;
}
/* Ênfase */
.Itemlista strong {
    font-weight: bold;
}
/* Container dos itens da tabela*/
.divAcoesTabela {
    display: flex;
    gap: 0.5em;
    justify-content: center;    
    align-items: center;
}
/* Container do ícone e texto */
.divAcoesTabela a {
    color: black;
}
/* ícone */
.divAcoesTabela a svg {
    color: var(--dark-blue);
}
/* Container do ícone e texto mobile*/
.divAcoes a {
    color: black;
}
/* ícone mobile */
.divAcoes a svg {
    color: var(--dark-blue);
}
/* Estilo para status ativo no destkop */
.ativo {
    background-color: #A7D397;
}
/* Estilo para status desativado no destkop */
.desativado {
    background-color: #FF6969;
}
/* Estilo para status ativo no mobile */
.ativoMobile {
    color: green !important;
    font-weight: bold;
}
.processando {
    color: orange !;
    font-weight: bold;
}
/* Estilo para status desativado no mobile */
.desativadoMobile {
    color: #FF6969 !important;
    font-weight: bold;
}
/* Container do mobile */
.ContainerIconeMobile {
    margin-left: 0.4em;
}

.Itemlista a {
    color: black;
}

.Itemlista a:hover {
    color: var(--pink);
}

.linksCampanha {
    color: var(--black);
}

.linksCampanha:hover {
    color: var(--pink);
}