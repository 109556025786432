/* Container principal */
.PerfilInfluenciador {
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}
/* Titulo principal */
.PerfilInfluenciador h2 {
    text-align: center;
    font-size: 32px;    
    font-weight: bold;
    color: var(--pink);
}
/* Formulario */
.PerfilInfluenciador form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;    
}
.PerfilInfluenciador form img {
    width: 300px;
    padding: 1em 0;
    margin: 0 auto;
}
/* SubTitulo */
.PerfilInfluenciador form h3 {
    text-align: center;
    font-size: 20px;
    color: var(--lilas);
}
/* Alertas input */
.PerfilInfluenciador form p {
    color: var(--white);
}
/* Linha que separa */
.PerfilInfluenciador form hr {
    color: var(--white);
}
/* Container botões de navegação */
.BotoesNavegação {    
    display: flex;
    gap: 0.5em;
    margin-top: 2em;
}
/* Linha que separa */
.PerfilAnunciante form hr {
    color: var(--white);
}
/* Botão editar */
.BotoesNavegação button {
    color: var(--white);
    background: var(--dark-blue) !important;
    width: 100%;
    margin-top: 0.5em; 
}
/* Botão editar quando passa o mouse */
.BotoesNavegação button:hover {
    background-color: var(--pink) !important;
}

.FormPdf button {
    margin-bottom: 1em;
    color: var(--white);
    font-weight: bold;
}

@media screen and (max-width: 1360px) {       
    .PerfilInfluenciador {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 500px) {       
    .PerfilInfluenciador form {
        padding: 36px 16px;        
    }  
}
  
