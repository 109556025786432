/* Container Principal */
.EstatisticaDaCampanha {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column; 
    margin-top: 1em;
    color: var(--white);
    font-size: 18px;
}
/* Título */
.EstatisticaDaCampanha h3 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;   
    margin-bottom: 1em; 
    color: #D0BFFF;
}
/* Link ir para campanha */
.EstatisticaDaCampanha a span {
    display: flex;
    align-items: center;    
    color: #D0BFFF;
    font-weight: bold;   
}
/* Listas */
.EstatisticaDaCampanha ul {
    list-style: none;
}
/* Link ir para campanha ao passar o mouse*/
.EstatisticaDaCampanha a span:hover {
    color: var(--white);
}

@media screen and (max-width: 600px) {       
    .EstatisticaDaCampanha {
        padding: 36px 16px;
        
    }
}