/* Container principal */
.PerfilAnunciante {
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center;  
    gap: 2em;  
}
/* Titulo principal */
.PerfilAnunciante h2 {
    text-align: center;
    font-size: 32px;    
    font-weight: bold;
    color: var(--pink);
}
/* Container do email e senha */
.PerfilAnunciante section {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;     
}
/* Botoes  do email e senha*/
.PerfilAnunciante section button {
    margin: 1em;
}
/* Caso já seja verificado */
.PerfilAnunciante section p {
    color: var(--lilas);
    /* margin-top: 0.6em; */
}
/* Titulo email */
.PerfilAnunciante section label {
    display: block;
    /* margin-bottom: 8px; */
    font-size: 22px;
    color: var(--white);
}
/* Container do texto */
.PerfilAnunciante section div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px;  
    margin-top: 0.4em;
    margin-bottom: 0.4em;   
}
/* Container do ícone */
.PerfilAnunciante section div svg {
    margin: 0 0.2em;
    color: var(--white); 
}
/* Onde fica o texto */
.PerfilAnunciante section div label {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;      
}
/* Input */
.PerfilAnunciante section div input {
    background-color: var(--white);    
    border: none;
    width: 100%;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black; 
}

/* Formulario */
.PerfilAnunciante form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;    
}
/* SubTitulo */
.PerfilAnunciante form h3 {
    text-align: center;
    font-size: 20px;
    color: var(--lilas);
}
/* Alertas input */
.PerfilAnunciante form p {
    color: var(--white);
}
/* Linha que separa */
.PerfilAnunciante form hr {
    color: var(--white);
}
/* Container botões de navegação */
.BotoesNavegação {    
    display: flex;
    gap: 0.5em;
    margin-top: 2em;
}
/* Olho de mostrar/esconder senha */
.ClickOlho:hover {
    cursor: pointer;
}

@media screen and (max-width: 1360px) {       
    .PerfilAnunciante {
        padding: 1em 0.5em;  
    }  
}

@media screen and (max-width: 500px) {       
    .PerfilAnunciante section {
        padding: 36px 18px;        
    }  
    .PerfilAnunciante form {
        padding: 36px 18px;
    }
}

