/* Container principal */
.MeusChamadosAnunciante {       
    padding: 1em 2em; 
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.MeusChamadosAnunciante h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);
    margin-bottom: 1.2em;
}
/* Tabela */
.MeusChamadosAnunciante table {
    width: auto;    
    
}
/* Chamado em aberto */
.aberto {
    color: #FFB534 !important;
}
/* Chamado fechado */
.fechado {
    color: #3081D0 !important;
}

@media screen and (max-width: 1360px) {       
    .MeusChamadosAnunciante {
        padding: 1em 0.5em; 
    }  
}


