/* Container principal */
.AceitarAnunciantes {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.AceitarAnunciantes h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.AceitarAnunciantes a {
    color: var(--dark-blue);
}

/* Container maior */
.AceitarAnunciantes div {
    margin: 0.6em;
}
/* container menor */
/* .AceitarInfluenciadores div div {    
    border: solid black 1px;
} */

.AceitarAnunciantes aside {    
    display: flex;
    justify-content: space-around;
    width: auto;
    align-items: center;
    gap: 1em;
    margin: 1em 0;
}

.AceitarAnunciantes aside div {
    margin: 0 !important;
}

/* Título de pesquisa e tabela */
.AceitarAnunciantes h6 {
    margin: 1em 0;
    text-align: center;
    font-size: 26px;
}


.AceitarAnunciantes div label {
    display: none;
}

.AceitarAnunciantes nav {    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;    
}

.AceitarAnunciantes nav div div {
    border: solid black 1px;
}

@media screen and (max-width: 850px) {       
    .AceitarAnunciantes aside {
        flex-direction: column;
        gap: 1em;
    }     
}

@media screen and (max-width: 700px) {       
    .AceitarAnunciantes nav {
        flex-direction: column;
    }     
}

@media screen and (max-width: 550px) {       
    .CelulaBotoes {
        width: 300px;
    }     
}