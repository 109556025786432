/* Container principal */
.PlanosAnunciantes {       
    padding: 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center; 
}
/* Titulo principal */
.PlanosAnunciantes h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 1em;
}
/* Link para perfil dos anunciantes */
.PlanosAnunciantes table a {    
    color: black;
}
/* Link para perfil dos anunciantes ao passar o mouse */
.PlanosAnunciantes table a:hover {    
    color: var(--pink);
}
/* Planos ao passar o mouse */
.PlanosAnunciantes table label:hover {
    color: var(--pink);
    cursor: pointer;
}
/* Expirado */
.PlanosAnunciantes table span {
    color: red;
    font-weight: bold;
}
/* Icone de ações ao passar o mouse */
.PlanosAnunciantes table svg:hover {
    color: var(--pink);
    cursor: pointer;
}
/* Bem vindo */
.PlanosAnunciantes p {
    color: var(--dark-blue);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 1360px) {       
    .PlanosAnunciantes {
        padding: 1em 0.5em; 
    }  
}

