/* Container  */
.Vantagens {
    background: url("./fundo.png") no-repeat;
    background-size: cover;
    
    height: auto;
    margin-top: 3em;   
    padding: 3em 0em;
    
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1em;
    box-sizing: border-box;    
}