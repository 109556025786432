/* Container principal */
.ChatSuporte {       
    padding: 6em 0.5em 2em 0.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
}
/* Titulo principal */
.ChatSuporte h2 {
    text-align: center;
    font-size: 32px;
    color: var(--pink);    
}
/* Descrição do problema */
.ChatSuporte h3 {
    text-align: center;
    font-size: 22px;
    color: var(--dark-blue);
    margin-bottom: 1.5em;
}
/* Container do chat */
.Chat {    
    justify-content: space-around;   
    display: flex;     
    width: 100%;
    margin-bottom: 2em;
}
.LadoUsuario {    
    display: flex;
    
}

.LadoUsuario img {
    width: 250px;
}
.PrimeiraMensagem {
    background-color: #F2F1EB;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    list-style: none;
    width: 300px;
    height: 250px;    
    
    padding: 0.4em;
    border-radius: 10px 10px 0px 10px;
   
    
    padding: 1em;
    overflow: auto; 

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
/* Mensagens usuário */
.PrimeiraMensagem li {
    background-color: orange;
    padding: 0.2em;
    margin: 0.3em;
    border-radius: 10px 10px 0px 10px;
}
.MensagemUsuario {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    list-style: none;
    background-color: #F2F1EB;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;   
    padding: 0.4em;
    border-radius: 10px 10px 10px 0px;
    margin-left: 1em;
    
    padding: 1em;
    width: 300px;
    height: 250px;
    overflow: auto; 
}
/* Mensagens suporte */
.MensagemUsuario li {
    background-color: #4CB9E7;
    padding: 0.2em;
    margin: 0.3em;
    border-radius: 10px 10px 10px 0px;
}

/* Nome do usuário */
.Chat h3 {
    text-align: center;
    font-size: 18px;
    color: var(--pink);
    margin-bottom: 1em;
}
/* Ênfase */
.Chat strong {
    font-weight: bold;
}
/* Formulário */
.ChatSuporte form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1150px) {       
    .Chat {        
        flex-direction: column;
        align-items: center;
        padding: 1em 0em;
        gap: 3em;
    }
}

@media screen and (max-width: 600px) {     
    .LadoUsuario img {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width: 500px) {     
    .LadoUsuario  {
        flex-direction: column;
        align-items: center;
    }
    .ChatSuporte form {
        padding: 36px 36px;
    }
}

