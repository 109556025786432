/* Container principal */
.VerPerfilAnunFinanceiro {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.VerPerfilAnunFinanceiro h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.VerPerfilAnunFinanceiro a {
    color: var(--dark-blue);
}

.Perfil:hover {
    cursor: pointer;
    color: var(--pink) !important;
}

.enviada {
    color: green !important;
}

.naoEnviada {
    color: orange !important;
}



