.EnvPropostasAnunciante {    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;   
}
.EnvPropostasAnunciante h3 {
    color: var(--dark-blue) !important;
    margin-bottom: 1em !important;
    font-size: 26px !important;
}
.EnvPropostasAnunciante h3 a {
    color: var(--pink);
}
/* Container do chat */
.Chat {    
    justify-content: space-around;   
    display: flex;     
    width: 100%;
    margin-bottom: 2em;    
}
.LadoUsuario {    
    display: flex;    
}

.LadoUsuario img {
    width: 250px;
}

/* Mensagens usuário */
.PrimeiraMensagem li {
    background-color: orange;
    padding: 0.2em;
    margin: 0.3em;
    border-radius: 10px 10px 0px 10px;
}
.MensagemUsuario {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    list-style: none;
    background-color: #F2F1EB;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;   
    padding: 0.4em;
    border-radius: 10px 10px 10px 0px;
    margin-left: 1em;
    
    padding: 1em;
    width: 300px;
    height: 250px;
    overflow: auto; 
}
/* Mensagens suporte */
.MensagemUsuario li {
    background-color: #4CB9E7;
    padding: 0.2em;
    margin: 0.3em;
    border-radius: 10px 10px 10px 0px;
}

.PrimeiraMensagem {
    background-color: #F2F1EB;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    list-style: none;
    width: 300px;
    height: 250px;    
    
    padding: 0.4em;
    border-radius: 10px 10px 0px 10px;
   
    
    padding: 1em;
    overflow: auto; 

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Formulário do filtro */
.EnvPropostasAnunciante form {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;     
    margin-top: 1em;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.6em;
    margin-bottom: 1em;    
}
/* SubTítulos */
.EnvPropostasAnunciante h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 0.5em;    
    color: var(--white);    
}
/* Personaliza o botão do filtro */
.EnvPropostasAnunciante button {
    background-color: var(--white) !important;
    height: 60% !important;
    margin-top: 2.3em;
}
/* Poem a mesma largura nas entradas de texto */
.EnvPropostasAnunciante input, .EnvPropostasAnunciante textarea {
    width: 260px !important;    
}
/* Text area */
.EnvPropostasAnunciante textarea {
    padding: 0.5em;
    border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 750px) {       
    .EnvPropostasAnunciante {        
        flex-wrap: nowrap;
        flex-direction: column;
    }     
}

@media screen and (max-width: 500px) {       
    .EnvPropostasAnunciante {        
        padding: 36px 16px;
    } 
}

/* Container chat */

@media screen and (max-width: 1200px) {       
    .Chat {    
        flex-direction: column; 
        align-items: center;
        gap: 6em;
    }
}

@media screen and (max-width: 600px) {       
    .LadoUsuario img {
        width: 200px;
        height: auto;
    }
}

@media screen and (max-width: 550px) {       
    .LadoUsuario img {
        width: 150px;
        height: auto;
    }
    .EnvPropostasAnunciante form {
        padding: 36px 36px;        
    }
}

@media screen and (max-width: 500px) {       
    .LadoUsuario img {
        width: 100px;
        height: 200px;        
    }
    .EnvPropostasAnunciante form {
        padding: 36px 28px;              
    }
}

@media screen and (max-width: 450px) {       
    .LadoUsuario img {
        width: 50px;
        height: 80px;        
    }
}
/* Propostas disponíveis */
/* Formulário do filtro */
.Proposta  {
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;     
    display: flex;     
    margin-top: 1em;
    justify-content: space-between;    
    align-items: center;
    gap: 0.6em;
    margin-bottom: 3em;  
    flex-direction: column;  
}
/* SubTítulos */
.Proposta h3 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 0.5em;    
    color: var(--white);    
}
/* Mensagem de caso não tenha nenhuma proposta disponível */
.Proposta span {
    text-align: center;
    font-size: px;
    margin-bottom: 0.5em;    
    color: var(--white);
}

.ContainerConfirmarCampanha {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContainerConfirmarCampanha h4 {
    font-size: 22px;
}

.ContainerConfirmarCampanha button {
    background-color: green !important;
    color: var(--white);
    margin: 1em 0 !important;
}



