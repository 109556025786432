/* Container principal */
.CardsPainel {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    padding: 1em;
    border-radius: 10px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 340px;
    justify-content: space-around;        
}
/* Imagem */
.CardsPainel img {
    width: auto;
    height: 200px;
    margin-bottom: 0.5em;    
}
.InformacaoDisponivel {
    color: red !important;
    font-weight: 370 !important;   
}
/* Sensacao de crescimento ao passar o mouse */
.CardsPainel:hover {
    cursor: pointer;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.025);
    transition: 0.18s;    
}