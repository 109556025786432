/* Container principal */
.BuscaEstatisticasAPI {
    padding: 1em 2em;      
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.BuscaEstatisticasAPI h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* Apresentação do nome */
.BuscaEstatisticasAPI h3 {
    text-align: center;
    font-size: 28px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--dark-blue);
}
/* Apresentação da campanha */
.BuscaEstatisticasAPI h4 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--dark-blue);
}
/* Filtros */
.BuscaEstatisticasAPI h5 {
    text-align: center;
    font-size: 26px;    
    font-weight: bold;
    color: var(--dark-blue);    
}
/* Total */
.BuscaEstatisticasAPI h6 {
    text-align: center;
    font-size: 26px;    
    font-weight: bold;
    color: var(--dark-blue);     
}
/* Container do filtro */
.BuscaEstatisticasAPI aside {
    display: flex;
    padding: 1em;    
    gap: 1em;
    width: auto;
    margin-bottom: 0.5em;
    align-items: center;      
    
}
/* Botões de filtrar e adicionar filtro */
.BuscaEstatisticasAPI aside button {
    height: 50%;
}
/* Espaçamento da mensagem pra tabela */
.BuscaEstatisticasAPI table {
    margin-top: 1em;
}
/* Cabeçalho da tabela */
.BuscaEstatisticasAPI table thead tr th {
    color: white;
}

@media screen and (max-width: 1360px) {       
    .BuscaEstatisticasAPI {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 700px) {       
    .BuscaEstatisticasAPI aside {
        flex-direction: column;
    }
}





