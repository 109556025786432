/* Container principal */
.EnviarTrabalho {
    padding: 1em 2em;     
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.EnviarTrabalho h2 {
    text-align: center;
    font-size: 32px;    
    font-weight: bold;
    color: var(--pink);    
}
/* SubTítulo */
.EnviarTrabalho h3 {
    text-align: center;
    font-size: 26px;
    color: var(--dark-blue);
    margin-top: 1.2em;
}
.EnviarTrabalho h5 {
    margin-top: 1em;
}
/* Links */
.EnviarTrabalho h5 a {
    text-align: center;
    font-size: 20px;
    color: var(--dark-blue);
    margin-top: 1.2em;
}
/* Links ao passar o mouse */
.EnviarTrabalho h5 a:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Formulário para enviar os arquivos */
.EnviarTrabalho form {
    border-radius: 20px;
    padding: 20px 36px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to right, var(--pink), var(--dark-blue));
    width: auto;
    display: flex;
    flex-direction: column;  
    margin-top: 2em;
}
/* Lista de arquivos enviados */
.EnviarTrabalho ul {
    list-style: none;    
    margin-top: 1em;
}
/* Item da lista */
.EnviarTrabalho ul li:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Botão enviar */
.botaoEnviar {
    background-color: #40A578;
    height: 36px;
}
/* Botão enviar ao passar o mouse */
.botaoEnviar:hover {
    background-color: #BFF6C3;    
}
/* Container do botão de adicionar link */
.ContainerBotaoAdicionarLink {
    margin: 1em 0;
    color: white;
}

.ContainerBotaoAdicionarLink:hover {
    cursor: pointer;
    color: lightgrey
}

@media screen and (max-width: 1360px) {       
    .EnviarTrabalho {
        padding: 1em 0.5em; 
    }  
}

