/* Container principal */
.VerPerfilInfluenciador {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}
/* Título */
.VerPerfilInfluenciador h3 {
    margin-bottom: 1.5em;
    text-align: center;
}
/* Cor na tabela */
.VerPerfilInfluenciador a {
    color: var(--dark-blue);
}



