/* Container principal */
.EstatisticasDeCampanha {
    padding: 1em 2em;    
    display: flex;
    flex-direction: column;
    align-items: center;    
}
/* Titulo principal */
.EstatisticasDeCampanha h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* SubTítulo */
.EstatisticasDeCampanha h3 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 1em;    
    font-weight: bold;
    color: var(--dark-blue);
}
/* Título do filtro */
.EstatisticasDeCampanha h6 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 1em;    
    font-weight: bold;
    color: var(--dark-blue);
}
/* Container do filtro */
.EstatisticasDeCampanha aside {
    background-color: lightgray;
    padding: 0.3em;
    border-radius: 20px;
    margin-bottom: 1em; 
}
.EstatisticasDeCampanha aside div {
    display: flex;
    align-items: center;
    gap: 0.8em;
}
.Autocomplete label {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    color: var(--white);
}
.Autocomplete input {
    background-color: var(--white);    
    border: none;
    width: auto;
    font-size: 16px;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    color: black;  
}
.Autocomplete div {
    background-image: linear-gradient(to right, var(--pink), var(--indigo), var(--dark-blue));
    display: flex;
    align-items: center;    
    width: auto;
    border-radius: 10px;  
}
.Autocomplete div svg {
    margin: 0 0.2em;
    color: var(--white);
}
/* Adicionar filtro */
.EstatisticasDeCampanha aside div span {
    display: flex;
    align-items: center;
    color: var(--dark-blue);
}
/* Adicionar filtro ao passar o mouse */
.EstatisticasDeCampanha aside div span:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Descrição */
.EstatisticasDeCampanha h4 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0.5em;
    font-weight: bold;    
}
.EstatisticasDeCampanha h5 {
    text-align: center;
    font-size: 26px;
    margin: 1em 0;
    font-weight: bold; 
}
/* Primeiro container */
.EstatisticasDeCampanha section {
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
    width: 100%;
}
/* ícone ir até perfil do influenciador ao passar o mouse */
.IconeInfluenciador:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Nome do influenciador ao passar o mouse */
.NomeInfluenciador:hover {
    cursor: pointer;
    color: var(--dark-blue);
}
Status ativo da transação
.statusAtivo {
    color: green !important;
    cursor: pointer;
}
Status desativado da transação
.statusDesativado {
    color: orange;
}

@media screen and (max-width: 1370px) {       
    .EstatisticasDeCampanha section {
        flex-direction: column;
        align-items: center;
        gap: 2em;
    }  
}

@media screen and (max-width: 1360px) {       
    .EstatisticasDeCampanha {
        padding: 1em 0.5em; 
    }  
}





