:root {
  /* Cores ISM */
  --white: #FFFFFF;
  --pink: #8B267f;
  --green: #00b96c;
  --black: #000000;
  --indigo: #6610f2;
  --dark-blue: #202764;
  --gold-chip: #C07F00;
  --green-sensor: #618264;
  --lilas: #D0BFFF;  

  /* Outras cores */
  --azul-claro: #339CFF;
  --azul-escuro: #0B3B60;
  --branco: #FFFFFF;
  --cinza: #6B6E71;
  --cinza-claro: #E7EBEF;

  /* Fontes */
  --fonte-principal: 'Public Sans', sans-serif;
  --fonte-secundaria: 'Archivo', sans-serif;
}

/* Reset css */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

