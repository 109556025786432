/* Container principal */
.FinalizarTrabalhoDoInfluenciador {
    padding: 1em 2em;  
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.FinalizarTrabalhoDoInfluenciador h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* Caso não tenha finalizado ainda */
.FinalizarTrabalhoDoInfluenciador span {
    text-align: center;
    font-size: 26px;
    color: var(--dark-blue);
}
/* Caso tenha arquivos */
.FinalizarTrabalhoDoInfluenciador h3 {
    text-align: center;
    font-size: 26px;
    color: var(--dark-blue);
}
/* explicação */
.FinalizarTrabalhoDoInfluenciador h4 {
    text-align: center;
    font-size: 22px;
    color: var(--dark-blue);
    margin-top: 1.2em;
}
/* Container do link */
.FinalizarTrabalhoDoInfluenciador h5 {    
    margin-top: 1.2em;    
}
/* Link */
.FinalizarTrabalhoDoInfluenciador h5 a {
    text-align: center;
    font-size: 22px;
    color: var(--dark-blue);
    margin-top: 1.2em;    
}
/* Link ao passar o mouse */
.FinalizarTrabalhoDoInfluenciador h5 a:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Lista de arquivos enviados */
.FinalizarTrabalhoDoInfluenciador ul {
    list-style: none;    
    margin-top: 1em;
    margin-bottom: 2em;
}
/* Item da lista */
.FinalizarTrabalhoDoInfluenciador ul li:hover {
    cursor: pointer;
    color: var(--pink);
}
/* Container confirmar trabalho finalizado */
.ContainerConfirmarCampanha {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContainerConfirmarCampanha h4 {
    font-size: 22px;
}

.ContainerBotao {
    margin-top: 1.4em;
    display: flex;
    align-items: center;
    gap: 2em;
}

.ContainerConfirmarCampanha button {
    /* background-color: green !important; */
    color: var(--white);
    margin-top: 1em;
}

@media screen and (max-width: 1360px) {       
    .FinalizarTrabalhoDoInfluenciador {
        padding: 1em 0.5em; 
    }  
}



