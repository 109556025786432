/* Container principal */
.VerPerfilInfluFinanceiro {
    margin-top: 2em;
    font-size: 20px;
    color: var(--pink);
}

/* Título */
.VerPerfilInfluFinanceiro h3 {
    margin-bottom: 1.5em;
    text-align: center;    
}

/* Cor na tabela */
.VerPerfilInfluFinanceiro a {
    color: var(--dark-blue);
}


.enviada {
    color: green !important;
}

.naoEnviada {
    color: orange !important;
}

.Perfil:hover {
    cursor: pointer;
    color: var(--pink) !important;
}