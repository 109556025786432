/* Container dos botões */
.divBotoesModal {
    margin-top: 1em;
}
.divBotoesModal button {
    margin-right: 0.6em;
}

.FormPdf label {
    color: black;
}

.FormPdf input, .FormPdf select {
    border: solid var(--lilas) !important;    
}

.FormPdf button {
    margin-top: 0.8em;
}

.ListaNotaFiscal {
    margin-top: 1em;
    list-style: none;
}

.ListaNotaFiscal li {
    display: flex;
    gap: 0.2em;
}

.ListaNotaFiscal li:hover, .ListaNotaFiscal li label:hover {
    cursor: pointer;
    color: var(--pink);
}