/* Container principal */
.NotificacoesAnunciante {
    padding: 1em 2em;    
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Titulo principal */
.NotificacoesAnunciante h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 0.5em;
    font-weight: bold;
    color: var(--pink);
}
/* Link para ir até notificação */
.NotificacoesAnunciante table tbody tr td a {
    color: darkblue;
}
/* Link para ir até notificação ao passar o mouse */
.NotificacoesAnunciante table tbody tr td a:hover {
    color: var(--pink);
}

.Visto {
    color: green !important;
}

.Nova {
    color: darkorange !important;
}

@media screen and (max-width: 1360px) {       
    .NotificacoesAnunciante {
        padding: 1em 0.5em; 
    }  
}

