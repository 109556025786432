/* Container principal */
.PoliticaEPrivacidade {
    padding: 0em 2em 1em 2em;   
    display: flex;
    flex-direction: column;
    align-items: center;  
}
/* Botãovoltar */
.PoliticaEPrivacidadeBotao {
    padding: 1em 2em;
} 
/* Titulos */
.PoliticaEPrivacidade h2 {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    color: var(--pink);
    /* margin-bottom: 0.8em; */
}
/* Cada item das listas */
.PoliticaEPrivacidade div ul li {    
    list-style: none;    
}
/* Subtitulos */
.PoliticaEPrivacidade h3 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: var(--pink);   
    margin-bottom: 1em; 
}
/* Textos */
.PoliticaEPrivacidade div p {
    margin: 0.5em 0;       
}
/* Enfases */
.PoliticaEPrivacidade div p strong {
    font-weight: bold;
    color: var(--dark-blue);
}

@media screen and (max-width: 1360px) {       
    .PoliticaEPrivacidade {
        padding: 1em 0.5em; 
    }  
}

@media screen and (max-width: 500px) {    
    .PoliticaEPrivacidadeBotao {
        padding: 1em 1em;
    }  
}
