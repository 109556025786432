/* Titulo principal do nav */
.navbar-brand {
    color: #D0BFFF;
}

/* Manter a mesma cor ao passar o mouse */
.navbar-brand:hover {
    color: #D0BFFF;
}

/* Link ao abrir item do menu */
.dropdown-item a {
    color: var(--black);
}

/* Item da lista ao abrir Item do menu, quando clicado */
.dropdown-item:active {
    background-color: var(--white);
}

/* Ao passar o mouse no link ao abrir item do menu */
.dropdown-item a:hover {
    color: #D0BFFF;
}

/* Ao passar o mouse no limpar */
.limpar:hover {
    cursor: pointer;
    color: var(--pink);
}

/* Imagem do perfil */
.Perfil img {
    width: 45px;
}

.verTodas {
    color: black;
    font-size: 15px;
    text-align: center;    
}

.verTodas:hover {
    color: var(--pink);    
}

.hr {
    margin: 0.2em 0;
}


@media (max-width: 1300px) {
    .navbar-nav {
        gap: 1em !important;
    }
}



@media (max-width: 1200px) {
    .navbar-nav {
        gap: 0 !important;
    }
}

/* @media (max-width: 1100px) {
    .navbar-nav {
        margin-left: 0em !important;
    }
} */

@media (max-width: 1060px) {
    .nav-link {
        font-size: 13.8px !important;
    }
}
/* Quando entrar no menu mobile */
@media (max-width: 900px) {
    .nav-link {
        font-size: 16px !important;
    }
}



/* @media (min-width: 1000px) {
    .Perfil {
        position: absolute;
        right: 0;
        padding: 0 0.2em;
        
    }

    
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
        gap: 2em;
        flex-wrap: wrap;
    }

} */